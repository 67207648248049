import React, { useState, useRef, useEffect } from 'react';
import './DemographicsRangeSelector/DemographicsRangeSelector.css';

import Bar from '../Charts/Bar';
import Gauge from '../Charts/Gauge';
import DemographicsRangeSelector from './DemographicsRangeSelector/DemographicsRangeSelector';

const Demographics = props => {
  const {
    results,
    headerStats,
    onToggleFilter,
    filterCollectDataGroups,
    activeFilters,
    unFilteredSideResults
  } = props;
  const [ageGroupsParent, setAgeGroupsParent] = useState(null);
  // const [employmentParent, setEmploymentParent] = useState(null);
  const [demographicsActions, setDemograpicsActions] = useState(false);
  const [showRangeSelector, setShowRangeSelector] = useState(true);
  const [ageFilteringOnSelection, setAgeFilteringOnSelection] = useState(true);
  const [unfilteredAgeGroupsData, setUnfilteredAgeGroupsData] = useState(null);

  const dropdownTogglerRef = useRef(null);

  useEffect(
    () => {
      if (activeFilters.length) {
        setUnfilteredAgeGroupsData(getUnfilteredAgeGroupsData());
      } else {
        setUnfilteredAgeGroupsData(null);
      }
    },
    [ageFilteringOnSelection, unFilteredSideResults.ageGroups]
  );

  const surveyHasMultipleLanguagesInResults =
    unFilteredSideResults &&
    unFilteredSideResults.languages &&
    unFilteredSideResults.languages.findIndex(
      language => language.value === '1.000'
    ) === -1;

  const activeAgeFilters =
    activeFilters &&
    activeFilters.length &&
    activeFilters.some(
      activeFilter =>
        activeFilter.valuePath &&
        activeFilter.valuePath === 'attributes.userData.age'
    );

  const getUnfilteredAgeGroupsData = () => {
    let unfilteredData = [...unFilteredSideResults.ageGroups].map(ageGroup => ({
      ...ageGroup
    }));
    if (ageFilteringOnSelection) {
      const filteredData = [...results.ageGroups].map(ageGroup => ({
        ...ageGroup
      }));
      /* eslint-disable no-param-reassign */
      unfilteredData = unfilteredData.reduce(
        (unfilteredValuesWithoutSelection, currentDataPoint) => {
          if (filteredData && filteredData.length) {
            const matchingValue = filteredData.find(
              datapoint => datapoint.x === currentDataPoint.x
            );
            if (matchingValue) {
              currentDataPoint.y -= matchingValue.y;
            }
            if (currentDataPoint.y < 0) {
              currentDataPoint.y = 0;
            }
            unfilteredValuesWithoutSelection.push(currentDataPoint);
          }
          return unfilteredValuesWithoutSelection;
        },
        []
      );
      /* eslint-enable no-param-reassign */
    }
    return unfilteredData;
  };

  let unfilteredNumberOfResults = 0;
  if (results && results.unfilteredTotalAgeGroupsCount) {
    if (ageFilteringOnSelection && results.totalAgeGroupsCount) {
      unfilteredNumberOfResults =
        results.unfilteredTotalAgeGroupsCount - results.totalAgeGroupsCount;
    } else {
      unfilteredNumberOfResults = results.unfilteredTotalAgeGroupsCount;
    }
  }

  return (
    <div className="demographic-results-container">
      <div className="filter-results-container">
        <div className="filter-results-title">Gender</div>
        {results && results.genders && headerStats && headerStats.responses ? (
          <Gauge
            data={results.genders}
            width="320"
            height="60"
            onChartClick={onToggleFilter}
            filterCollectDataGroup={filterCollectDataGroups[0]}
          />
        ) : null}
        {headerStats && !headerStats.responses ? (
          <div className="no-chart-data">No data</div>
        ) : null}
      </div>
      {surveyHasMultipleLanguagesInResults && (
        <div className="filter-results-container">
          <div className="filter-results-title">Language</div>
          {headerStats && headerStats.responses ? (
            <Gauge
              data={results.languages.filter(
                language => language.valueCount > 0
              )}
              width="320"
              height="60"
              onChartClick={onToggleFilter}
              filterCollectDataGroup={filterCollectDataGroups.find(
                filter => filter.name === 'languages'
              )}
            />
          ) : null}
          {headerStats && !headerStats.responses ? (
            <div className="no-chart-data">No data</div>
          ) : null}
        </div>
      )}
      <div
        className="filter-results-container"
        ref={elem => setAgeGroupsParent(elem)}
      >
        <div className="filter-results-options-container">
          <div className="filter-results-title">Age groups</div>
          <div
            role="presentation"
            onClick={() => setDemograpicsActions(prev => !prev)}
            ref={dropdownTogglerRef}
          >
            <span className="filter-results-options-button">...</span>
          </div>
        </div>
        {results &&
        results.ageGroups &&
        headerStats &&
        headerStats.responses ? (
          <Bar
            data={results.ageGroups}
            color="#5200F1"
            parent={ageGroupsParent}
            height="150"
            onChartClick={onToggleFilter}
            tickFrequency="2"
            filterCollectDataGroup={filterCollectDataGroups[1]}
            unfilteredAgeGroupsData={unfilteredAgeGroupsData}
            numberOfResults={
              results && results.totalAgeGroupsCount
                ? results.totalAgeGroupsCount
                : 0
            }
            unfilteredNumberOfResults={unfilteredNumberOfResults}
            displayRatingPercentage
            displayPercentageYLabel
            isAgeGroupsChart
            activeFilteringOnThisChart={activeAgeFilters}
          />
        ) : null}
        <div>
          <DemographicsRangeSelector
            onToggleFilter={onToggleFilter}
            filterCollectDataGroup={filterCollectDataGroups[3]}
            activeFilters={activeFilters}
            showRangeSelector={showRangeSelector}
            setShowRangeSelector={setShowRangeSelector}
            demographicsActions={demographicsActions}
            setDemograpicsActions={setDemograpicsActions}
            dropdownTogglerRef={dropdownTogglerRef}
            showCompareToTotalSample={
              activeFilters && activeFilters.length && !activeAgeFilters
            }
            ageFilteringOnSelection={ageFilteringOnSelection}
            setAgeFilteringOnSelection={setAgeFilteringOnSelection}
          />
        </div>
        {headerStats && !headerStats.responses ? (
          <div className="no-chart-data">No data</div>
        ) : null}
      </div>
      {/*
        <div
          className="filter-results-container"
          ref={elem => setEmploymentParent(elem)}
        >
          <div className="filter-results-title">Employment</div>
          {results &&
          results.employementTypes &&
          headerStats &&
          headerStats.responses ? (
            <Bar
              data={results.employementTypes}
              color="#5200F1"
              parent={employmentParent}
              height="150"
              onChartClick={onToggleFilter}
              tickFrequency="4"
              filterCollectDataGroup={filterCollectDataGroups[2]}
            />
          ) : null}
          {headerStats && !headerStats.responses ? (
            <div className="no-chart-data">No data</div>
          ) : null}
        </div>
        */}
    </div>
  );
};

export default Demographics;
