import React, { useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';

import AppBar from '../../base/components/AppBar/AppBar';
import TestingBanner from '../../base/components/TestingBanner/TestingBanner';
import SavingTranslationError from '../../base/components/SavingTranslationError/SavingTranslationError';

import styles from './CampaignsRoute.module.css';

import DutchStrings from '../../strings/nl';
import FrenchStrings from '../../strings/fr';
import EnglishStrings from '../../strings/en';
import GermanStrings from '../../strings/de';

import { GET_AUTHENTICATED_CLIENT } from '../../graphql/Auth';
import CookiesPopup from '../../base/components/CookiesPopup/CookiesPopup';

const CampaignsRoute = props => {
  const { component: Component, location, ...rest } = props;

  const languages = {
    ENGLISH: 'English',
    DUTCH: 'Dutch',
    FRENCH: 'French'
  };

  const surveyLanguageProperties = {
    English: 'EN',
    Dutch: 'NL',
    French: 'FR',
    German: 'DE'
  };

  const [routeContainer, setRouteContainer] = useState(null);
  const [hideBufflLogo, setHideBufflLogo] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(languages.ENGLISH);
  const [customTranslationsLoaded, setCustomTranslationsLoaded] = useState(
    false
  );
  const [customThemeBaseColor, setCustomThemeBaseColor] = useState(null);
  const [customThemeImage, setCustomThemeImage] = useState(null);
  const [savingTranslationError, setSavingTranslationError] = useState(null);
  const [clientId, setClientId] = useState(null);

  const loadedStrings = {
    Dutch: DutchStrings,
    French: FrenchStrings,
    English: EnglishStrings,
    German: GermanStrings
  };

  const [translations, setTranslations] = useState(
    selectedLanguage && loadedStrings[selectedLanguage]
      ? loadedStrings[selectedLanguage]
      : loadedStrings.Dutch
  );

  const [loadCredentials] = useLazyQuery(GET_AUTHENTICATED_CLIENT, {
    fetchPolicy: 'network-only',
    onCompleted: response => {
      if (
        response &&
        response.getAuthenticatedClient &&
        response.getAuthenticatedClient.client
      ) {
        setClientId(response.getAuthenticatedClient.client);
      }
    }
  });

  const isTesting = () => location.search === '?test=true';

  useEffect(() => {
    document.documentElement.style.backgroundColor = '#FFFFFF';
    document.getElementsByTagName('body')[0].style.backgroundColor = '#FFFFFF';
    if (isTesting() && !clientId) {
      const token = localStorage.getItem('token');
      if (token) {
        loadCredentials({ variables: { token } });
      }
    }
  }, []);

  const setCustomTranslations = (language, customCopies) => {
    const webSurveyLanguage = language || selectedLanguage;
    if (language) setSelectedLanguage(language);
    const updatedTranslations = Object.assign(
      {},
      loadedStrings[webSurveyLanguage]
    );
    customCopies.forEach(customCopy => {
      if (
        customCopy.active &&
        customCopy.translations &&
        customCopy.translations.length
      ) {
        customCopy.translations.forEach(translation => {
          if (translation[surveyLanguageProperties[webSurveyLanguage]]) {
            updatedTranslations[translation.KEY] =
              translation[surveyLanguageProperties[webSurveyLanguage]];
          }
        });
      }
    });
    setTranslations(updatedTranslations);
    setCustomTranslationsLoaded(true);
  };

  const updateTranslationsLanguage = language =>
    setTranslations(loadedStrings[language]);

  const pageContent = (renderProps, strings) => (
    <Component
      {...renderProps}
      strings={strings}
      routeContainer={routeContainer}
      setHideBufflLogo={setHideBufflLogo}
      setCustomThemeBaseColor={setCustomThemeBaseColor}
      setCustomThemeImage={setCustomThemeImage}
      customTranslationsLoaded={customTranslationsLoaded}
      setCustomTranslations={(language, customCopies) =>
        setCustomTranslations(language, customCopies)
      }
      selectedLanguage={selectedLanguage}
      setSelectedLanguage={setSelectedLanguage}
      translations={translations}
      updateTranslationsLanguage={language =>
        updateTranslationsLanguage(language)
      }
      savingTranslationError={savingTranslationError}
      setSavingTranslationError={setSavingTranslationError}
      clientId={clientId}
    />
  );

  return (
    <div className={styles.container} ref={r => setRouteContainer(r)}>
      {isTesting() && (
        <TestingBanner customThemeBaseColor={customThemeBaseColor} />
      )}
      <CookiesPopup isTesting={isTesting()} />
      <AppBar
        hideBufflLogo={hideBufflLogo}
        customThemeImage={customThemeImage}
      />
      <div className={styles.mainContainer}>
        <Route
          {...rest}
          render={renderProps => pageContent(renderProps, loadedStrings)}
        />
      </div>
      {savingTranslationError && <SavingTranslationError />}
    </div>
  );
};

export default CampaignsRoute;
