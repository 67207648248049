import React, { useState } from 'react';

import styles from './QuestionsList.module.css';
import editIcon from '../../../../../../../../../assets/img/edit-black.svg';
import Popup from '../../../../../../../Popup';
import EditQuestionPopup from '../EditQuestionPopup/EditQuestionPopup';

export default ({
  graph,
  mergedGraphQuestions,
  colorsPallete,
  hiddenQuestionIndexes,
  saveMergedGraph,
  saveMergedGraphQuestion,
  parseQuestion,
  isMatrixQuestion
}) => {
  const [editPopupQuestionId, setEditPopupQuestionId] = useState(false);

  const isQuestionHidden = index => hiddenQuestionIndexes.indexOf(index) === -1;

  const onActiveToggleClick = index => {
    let newHiddenQuestionIndexes = [...hiddenQuestionIndexes];
    if (isQuestionHidden(index)) {
      newHiddenQuestionIndexes = [...newHiddenQuestionIndexes, index];
    } else {
      newHiddenQuestionIndexes = newHiddenQuestionIndexes.filter(
        i => i !== index
      );
    }

    saveMergedGraph({
      hiddenQuestionIndexes: newHiddenQuestionIndexes
    });
  };

  const onSaveUpdatedMergeGraphQuestion = mergedGraphQuestionData => {
    setEditPopupQuestionId(null);
    saveMergedGraphQuestion(editPopupQuestionId, mergedGraphQuestionData);
  };

  const getMergedGraphQuestionDetails = question =>
    graph &&
    graph.questions &&
    graph.questions.length &&
    graph.questions.find(
      q =>
        q.id &&
        question &&
        question.id &&
        q.id.toString() === question.id.toString()
    );

  const getSingleQuestion = (question, index) => {
    const mergedGraphQuestionDetails = getMergedGraphQuestionDetails(
      question,
      index
    );

    const questionColorCode =
      mergedGraphQuestionDetails &&
      mergedGraphQuestionDetails.colorCode &&
      mergedGraphQuestionDetails.colorCode !== ''
        ? mergedGraphQuestionDetails.colorCode
        : colorsPallete[index];

    const questionName =
      mergedGraphQuestionDetails &&
      mergedGraphQuestionDetails.name &&
      mergedGraphQuestionDetails.name !== ''
        ? mergedGraphQuestionDetails.name
        : question && parseQuestion(question.question);

    return (
      <div
        className={styles.question}
        title={questionName}
        key={`merged-graph-q-${question.id.toString()}-${index.toString()}`}
      >
        <div
          style={{
            backgroundColor: questionColorCode
          }}
          className={styles.questionColor}
        />
        {!isMatrixQuestion && (
          <div className={styles.questionNumber}>
            Q{question && question.formattedGlobalIndex}
          </div>
        )}
        <div
          className={`${styles.questionString} ${
            isMatrixQuestion ? styles.matrixQuestionString : ''
          }`}
        >
          {questionName}
        </div>
        <div>
          <img
            src={editIcon}
            alt="Edit"
            className={styles.editIcon}
            role="presentation"
            onClick={() => setEditPopupQuestionId(question.id)}
          />
        </div>
        <div className={styles.questionToggle}>
          <span role="presentation">
            <label className={styles.questionSwitch} htmlFor="checkbox">
              <input
                type="checkbox"
                checked={!isQuestionHidden(index)}
                className={styles.input}
              />
              <span
                className={styles.questionSlider}
                style={{
                  backgroundColor: !isQuestionHidden(index)
                    ? '#d4d4d4'
                    : questionColorCode
                }}
                role="presentation"
                onClick={e => {
                  e.stopPropagation();
                  onActiveToggleClick(index);
                }}
              />
            </label>
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.questionsList}>
          {mergedGraphQuestions.map((question, index) =>
            getSingleQuestion(question, index)
          )}
        </div>
      </div>
      {editPopupQuestionId ? (
        <Popup
          component={
            <EditQuestionPopup
              editPopupQuestionId={editPopupQuestionId}
              onClose={() => setEditPopupQuestionId(null)}
              mergedGraphQuestions={mergedGraphQuestions}
              onSaveMergedGraphQuestionClick={onSaveUpdatedMergeGraphQuestion}
              parseQuestion={parseQuestion}
              colorsPallete={colorsPallete}
              getMergedGraphQuestionDetails={getMergedGraphQuestionDetails}
            />
          }
          customStyles={{ padding: 'none', overflow: 'visible' }}
          onClose={() => setEditPopupQuestionId(null)}
        />
      ) : null}
    </>
  );
};
