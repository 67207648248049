import React from 'react';

import FilterLabel from './components/FilterLabel';
import Icon from '../../../../../../Icon';
import '../../../FiltersBar.css';

const SingleFilter = props => {
  const {
    filter,
    onToggleFilter,
    resultBlocks,
    mutuallyExclusive,
    showFiltersOnly,
    sortedQuestions
  } = props;

  return (
    <div
      className={`single-active-filter ${
        mutuallyExclusive ? 'single-active-filter-warning' : ''
      }`}
    >
      <span className="single-active-filter-label">
        {filter ? (
          <FilterLabel
            filter={filter}
            resultBlocks={resultBlocks}
            sortedQuestions={sortedQuestions}
          />
        ) : null}
      </span>
      {!showFiltersOnly ? (
        <span
          className="remove-single-active-filter"
          role="presentation"
          onClick={() => {
            onToggleFilter(
              filter.filterName,
              filter.valuePath,
              filter.value,
              null,
              filter.transformator
            );
          }}
        >
          <Icon type="close" style={{ color: '#5200f1' }} />
        </span>
      ) : null}
    </div>
  );
};

export default SingleFilter;
