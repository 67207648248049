import React, { useEffect, useMemo, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';

import useLazyQuery from '../../../../../../surveys/hooks/useLazyQuery';

import styles from './InsightsPopup.module.css';
import Loader from '../../../../Loader/Loader';
import tickIcon from '../../../../../../assets/img/tick.svg';
import closeIcon from '../../../../../../assets/img/delete.svg';
import {
  CREATE_INSIGHT,
  UPDATE_INSIGHT
} from '../../../../../../graphql/InsightsData';
import { GET_SURVEY_FRAMEWORKS } from '../../../../../../graphql/Survey';

import FiltersBar from '../../../Blocks/FiltersBar/FiltersBar';
import QuestionsSelectorDropdown from './components/QuestionsSelectorDropdown/QuestionsSelectorDropdown';
import InsightsTextarea from './components/InsightsTextarea/InsightsTextarea';
import { insightQuestionTypes } from '../../helpers/constants';
import Icon from '../../../../Icon';

import { getNestedFiltersFromActiveFilters } from '../../../../../../helpers/filtering';

export default ({
  survey,
  internalData,
  setInternalData,
  surveyRefetch,
  activeFilters,
  filtersRelation,
  sortedQuestions,
  showAddInsightsPopup,
  setShowAddInsightsPopup,
  resultBlocks,
  stats,
  parseQuestion,
  getQuestionIcon,
  getRichTextString,
  activeSorting,
  activeFilteringOnSelection,
  activePresentationMode,
  surveyGraphs,
  getSurveyGraphs,
  viewToken
}) => {
  const questionsSelectorDropdownParentRef = useRef();

  const [createInsight, { loading: isCreateInsightLoading }] = useMutation(
    CREATE_INSIGHT
  );
  const [updateInsight, { loading: isSaveInsightLoading }] = useMutation(
    UPDATE_INSIGHT
  );
  const searchSurvey = useLazyQuery(GET_SURVEY_FRAMEWORKS, {
    fetchPolicy: 'no-cache'
  });

  const isLoading = isCreateInsightLoading || isSaveInsightLoading;

  useEffect(
    () => {
      if (showAddInsightsPopup && showAddInsightsPopup.baseQuestionId) {
        setInternalData({
          ...internalData,
          filters: {
            activeFilters,
            filterRelation: filtersRelation
          },
          questions: [
            {
              questionId: showAddInsightsPopup.baseQuestionId,
              type: showAddInsightsPopup.baseQuestionType
            }
          ]
        });
      }
    },
    [showAddInsightsPopup]
  );

  const getBaseQuestion = insight => {
    const firstQuestion =
      insight &&
      insight.questions &&
      insight.questions.length &&
      insight.questions[0];

    if (firstQuestion && firstQuestion.type === insightQuestionTypes.QUESTION) {
      return (
        sortedQuestions &&
        sortedQuestions.find(
          q =>
            q &&
            firstQuestion &&
            firstQuestion.questionId &&
            firstQuestion.questionId === q.id
        )
      );
    }

    if (firstQuestion && firstQuestion.type === insightQuestionTypes.TEMPLATE) {
      return (
        surveyGraphs &&
        surveyGraphs.length &&
        surveyGraphs.find(
          g =>
            g &&
            firstQuestion &&
            firstQuestion.questionId &&
            firstQuestion.questionId === g.id
        )
      );
    }

    return null;
  };

  const transformActiveState = (questions, activeState) =>
    questions.reduce((activeStateArray, q) => {
      if (activeState[q.questionId]) {
        return [
          ...activeStateArray,
          {
            questionId: q.questionId,
            active: activeState[q.questionId].active
          }
        ];
      }
      return activeStateArray;
    }, []);

  const baseQuestion = useMemo(() => getBaseQuestion(internalData), [
    internalData.questions
  ]);

  const onConfirmClick = async () => {
    const insightData = { ...internalData };
    insightData.survey = survey.id;

    //  Question filter value needs to be transformed into string from integer
    if (
      insightData &&
      insightData.filters &&
      insightData.filters.activeFilters &&
      insightData.filters.activeFilters.length
    ) {
      insightData.filters.activeFilters = insightData.filters.activeFilters.map(
        aF => {
          const newAF = { ...aF }; // Copy immutably to not alter active filter state

          if (newAF && newAF.valuePath === 'answer') {
            newAF.value = newAF.value.toString();
          }

          return newAF;
        }
      );
    }

    // Add
    if (showAddInsightsPopup && showAddInsightsPopup.baseQuestionId) {
      if (activeSorting && activeSorting.length) {
        insightData.questionSettings = {
          ...(insightData.questionSettings || {}),
          activeSorting
        };
      }

      if (activeFilteringOnSelection) {
        const filteringOnSelection = transformActiveState(
          insightData.questions,
          activeFilteringOnSelection
        );
        if (filteringOnSelection && filteringOnSelection.length) {
          insightData.questionSettings = {
            ...(insightData.questionSettings || {}),
            activeFilteringOnSelection: filteringOnSelection
          };
        }
      }

      if (activePresentationMode) {
        const presentationMode = transformActiveState(
          insightData.questions,
          activePresentationMode
        );
        if (presentationMode && presentationMode.length) {
          insightData.questionSettings = {
            ...(insightData.questionSettings || {}),
            activePresentationMode: presentationMode
          };
        }
      }

      // Check if there are graphs selected
      const insightGraphQuestions =
        insightData &&
        insightData.questions &&
        insightData.questions.filter(
          q => q.type === insightQuestionTypes.TEMPLATE
        );
      if (insightGraphQuestions && insightGraphQuestions.length) {
        const insightGraphIds = insightGraphQuestions.map(
          iGQ => iGQ.questionId
        );
        const selectedSurveyGraphs = surveyGraphs.filter(
          sG => insightGraphIds.indexOf(sG.id) > -1
        );

        if (selectedSurveyGraphs && selectedSurveyGraphs.length) {
          const refetchedSurvey = await searchSurvey({
            variables: {
              id: survey.id,
              ...(viewToken ? { viewToken } : {})
            }
          });

          if (
            refetchedSurvey &&
            refetchedSurvey.data &&
            refetchedSurvey.data.survey &&
            refetchedSurvey.data.survey.survey
          ) {
            const latestSurveyGraphs = getSurveyGraphs(
              refetchedSurvey.data.survey.survey
            );

            const graphFeatures = latestSurveyGraphs.map(sSG => ({
              graphId: sSG.id,
              featuresVisibility:
                sSG && sSG.features
                  ? sSG.features.map(f => ({
                      featureId: f.id,
                      active: f.active
                    }))
                  : [],
              unFilteredFeaturesVisibility:
                sSG &&
                sSG.unFilteredFeatures &&
                activeFilters &&
                activeFilters.length
                  ? sSG.unFilteredFeatures.map(f => ({
                      featureId: f.id,
                      active: f.active
                    }))
                  : [],
              hiddenQuestionIndexes:
                sSG &&
                sSG.hiddenQuestionIndexes &&
                sSG.hiddenQuestionIndexes.length
                  ? sSG.hiddenQuestionIndexes
                  : []
            }));

            insightData.questionSettings = {
              ...(insightData.questionSettings || {}),
              graphFeatures
            };
          }
        }
      }

      await createInsight({
        variables: insightData
      });
    }

    // Update
    if (showAddInsightsPopup && showAddInsightsPopup.insightId) {
      insightData.id = insightData._id;

      delete insightData.__typename;
      delete insightData.filters.__typename;
      if (
        insightData.filters &&
        insightData.filters.activeFilters &&
        insightData.filters.activeFilters.length
      ) {
        insightData.filters.activeFilters.forEach(aF => {
          const newAF = aF;
          delete newAF.__typename;
        });
      }
      if (insightData.questions && insightData.questions.length) {
        insightData.questions.forEach(q => {
          const nQ = q;
          delete nQ.__typename;
        });
      }

      // No need to update these
      if (insightData && insightData.questionSettings) {
        delete insightData.questionSettings;
      }

      await updateInsight({
        variables: insightData
      });
    }

    setShowAddInsightsPopup(false);
    setInternalData({});
    surveyRefetch();
  };

  const dropdownOptions = useMemo(
    () => {
      let options = [...(surveyGraphs || [])];

      const processedQuestions = sortedQuestions.reduce((acc, sQ) => {
        if (sQ.type !== 'Distributor') {
          return [
            ...acc,
            {
              formattedGlobalIndex: sQ.formattedGlobalIndex,
              id: sQ.id,
              question: parseQuestion(sQ.question),
              icon: getQuestionIcon(sQ),
              optionType: insightQuestionTypes.QUESTION
            }
          ];
        }
        return acc;
      }, []);

      options = [...options, ...processedQuestions];

      // Remove base question as it is already selected
      return options.filter(o => baseQuestion && o.id !== baseQuestion.id);
    },
    [sortedQuestions, baseQuestion]
  );

  const showQuestionPreview = question => {
    if (question) {
      if (question && question.optionType === insightQuestionTypes.TEMPLATE) {
        return (
          <>
            <Icon
              type="merged-graph"
              style={{
                color: '#5200f1',
                marginLeft: '5px',
                marginRight: '5px',
                fontSize: '10px'
              }}
            />
            {question.question}
          </>
        );
      }

      return (
        <>
          <span className={styles.baseQuestionContainerNumber}>
            {question.formattedGlobalIndex}.
          </span>
          <Icon
            type={getQuestionIcon(question)}
            style={{
              color: '#5200f1',
              marginLeft: '5px',
              marginRight: '5px',
              fontSize: '10px'
            }}
          />
          {parseQuestion(question.question)}
        </>
      );
    }

    return '/';
  };

  const isValidToComplete = () => {
    const descriptionRichText =
      internalData &&
      internalData.description &&
      getRichTextString(internalData.description);

    return (
      internalData &&
      internalData.name &&
      internalData.name !== '' &&
      descriptionRichText &&
      descriptionRichText !== ''
    );
  };

  return (
    <div
      className={styles.backgroundContainer}
      role="presentation"
      onClick={() => {
        setShowAddInsightsPopup(false);
        setInternalData({});
      }}
    >
      <div
        className={styles.popupContainer}
        role="presentation"
        onClick={e => e.stopPropagation()}
      >
        <div className={styles.popupContent}>
          <div className={styles.title}>Add insight</div>

          <div className={styles.subtitle}>
            Name (type of insight + short title)
          </div>
          <div className={styles.fieldsContainer}>
            <input
              onChange={e => {
                setInternalData({
                  ...internalData,
                  name: e.target.value
                });
              }}
              value={(internalData && internalData.name) || ''}
              className={styles.inputElement}
              placeholder="Type here"
            />
          </div>

          <div className={styles.subtitle}>Description</div>
          <div className={styles.fieldsContainer}>
            <InsightsTextarea
              internalData={internalData}
              setInternalData={setInternalData}
            />
          </div>

          <div className={styles.subtitle}>This insight is based on</div>
          <div className={styles.baseQuestionContainer}>
            {showQuestionPreview(baseQuestion)}
          </div>

          <div className={styles.subtitle}>
            If applicable, select other questions
          </div>
          <div>
            <QuestionsSelectorDropdown
              options={dropdownOptions}
              onSelectCompleted={() => {}}
              hideDropdownPopup={() => {}}
              setFeaturesScrollingDisabled={() => {}}
              placeholder="Select question(s)"
              emptyListPlaceholder="No questions to select"
              dropdownParentRef={questionsSelectorDropdownParentRef}
              internalData={internalData}
              setInternalData={setInternalData}
            />
          </div>

          {internalData &&
          internalData.filters &&
          internalData.filters.activeFilters &&
          internalData.filters.activeFilters.length ? (
            <div className={styles.filtersContainer}>
              <div className={styles.subtitle}>
                Segment (N = {stats.responses})
              </div>
              <div className={styles.filtersBarWrapper}>
                <FiltersBar
                  activeFilters={
                    (internalData &&
                      internalData.filters &&
                      internalData.filters.activeFilters) ||
                    []
                  }
                  filtersRelation={
                    (internalData &&
                      internalData.filters &&
                      internalData.filters.filterRelation) ||
                    'and'
                  }
                  nestedFilters={getNestedFiltersFromActiveFilters(
                    internalData.filters.activeFilters
                  )}
                  campaign={survey}
                  showFiltersOnly
                  sortedQuestions={sortedQuestions}
                  resultBlocks={resultBlocks}
                />
              </div>
            </div>
          ) : null}
        </div>
        <div className={styles.footer}>
          <div className={styles.details} />
          <div className={styles.actions}>
            {!isLoading ? (
              <img
                className={
                  isValidToComplete()
                    ? styles.confirm
                    : `${styles.confirm} ${styles.disabledConfirm}`
                }
                src={tickIcon}
                alt="Confirm icon"
                onClick={() => {
                  if (isValidToComplete()) {
                    onConfirmClick();
                  }
                }}
                role="presentation"
              />
            ) : null}
            {isLoading ? (
              <div className={styles.loaderContainer}>
                <Loader size="small" />
              </div>
            ) : null}
            <img
              className={styles.close}
              src={closeIcon}
              alt="Close icon"
              onClick={() => {
                setShowAddInsightsPopup(false);
                setInternalData({});
              }}
              role="presentation"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
