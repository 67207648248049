import React, { useEffect, useRef, useState } from 'react';

import dropdownIcon from '../../../../../../../../assets/img/dropdown-arrow-blue.svg';
import '../../../FiltersBar.css';

const FiltersRelationLabel = props => {
  const {
    index,
    filtersLength,
    filtersRelation,
    onSetFiltersRelation,
    showFiltersOnly
  } = props;
  const [showPopup, setShowPopup] = useState(false);
  const wrapperRef = useRef(null);

  const handleClickOutside = event => {
    if (
      !(
        wrapperRef &&
        wrapperRef.current &&
        wrapperRef.current.contains(event.target)
      )
    ) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (index < filtersLength - 1) {
    if (showFiltersOnly) {
      return (
        <div className="single-active-filter-filters-relation">
          {filtersRelation === 'and' ? 'AND' : 'OR'}
        </div>
      );
    }

    return (
      <div
        ref={wrapperRef}
        className={`single-active-filter-relations ${
          showPopup ? 'single-active-filter-relations-active' : ''
        }`}
        role="presentation"
        onClick={() => {
          setShowPopup(prev => !prev);
        }}
      >
        {filtersRelation.toUpperCase()}
        <img
          src={dropdownIcon}
          alt="dropdown icon"
          className="single-active-filter-relations-dropdown-icon"
        />
        {showPopup ? (
          <span
            className="single-active-filter-relations-dropdown"
            role="presentation"
            onClick={e => {
              e.stopPropagation();

              setShowPopup(false);

              if (filtersRelation === 'and') {
                onSetFiltersRelation('or');
              } else {
                onSetFiltersRelation('and');
              }
            }}
          >
            {filtersRelation === 'and' ? 'OR' : 'AND'}
          </span>
        ) : null}
      </div>
    );
  }

  return null;
};

export default FiltersRelationLabel;
