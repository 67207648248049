import React from 'react';

import ActiveFilters from './components/ActiveFilters';
import warningIcon from '../../../../../assets/img/exclamation.svg';

import './FiltersBar.css';

const FiltersBar = props => {
  const {
    activeFilters,
    onToggleFilter,
    resultBlocks,
    filtersRelation,
    nestedFilters,
    onResetFilters,
    onSetFiltersRelation,
    mutuallyExclusive,
    sortedQuestions,
    showFiltersOnly = false,
    removeNestedFilter
  } = props;

  if (activeFilters && activeFilters.length > 0) {
    return (
      <div className="filters-bar-container">
        {!showFiltersOnly ? (
          <span className="active-filters-title">Active filters</span>
        ) : null}
        <ActiveFilters
          activeFilters={activeFilters}
          onToggleFilter={onToggleFilter}
          resultBlocks={resultBlocks}
          filtersRelation={filtersRelation}
          nestedFilters={nestedFilters}
          sortedQuestions={sortedQuestions}
          onSetFiltersRelation={onSetFiltersRelation}
          mutuallyExclusive={mutuallyExclusive}
          showFiltersOnly={showFiltersOnly}
          removeNestedFilter={removeNestedFilter}
        />
        {!showFiltersOnly ? (
          <span
            className="reset-all-filters"
            role="presentation"
            onClick={onResetFilters}
          >
            Reset all filters
          </span>
        ) : null}
        {mutuallyExclusive ? (
          <span className="mutually-exclusive-filters-warning">
            <img
              src={warningIcon}
              alt="Warning"
              className="filters-warning-icon"
            />
            Mutually exclusive filters active
          </span>
        ) : null}
      </div>
    );
  }

  return null;
};

export default FiltersBar;
