import React from 'react';

import '../../../../FiltersBar.css';

const FilterLabel = props => {
  const { filter, resultBlocks, sortedQuestions } = props;

  const extremeUsersFilterLabels = {
    xLovers: 'Lovers x-axis: 25% highest scores',
    yLovers: 'Lovers y-axis: 25% highest scores',
    xLoversyLovers: 'Lovers x-axis and y-axis: highest 25% scores',
    xHaters: 'Haters x-axis: 25% lowest scores',
    yHaters: 'Haters y-axis: 25% lowest scores',
    xHatersyHaters: 'Haters x-axis and y-axis: lowest 25% scores'
  };

  const getFilterName = filterName => {
    if (filterName === 'genders') {
      return 'Genders';
    }
    if (filterName === 'employementTypes') {
      return 'Employment';
    }
    if (filterName === 'ageGroups') {
      return 'Age';
    }
    if (filterName === 'languages') {
      return 'Languages';
    }
    return '';
  };

  const getBlockIndex = blockId => {
    let blockIndex = null;

    const matchingQuestion =
      sortedQuestions &&
      sortedQuestions.length &&
      sortedQuestions.find(q => q.id === blockId);

    if (matchingQuestion && matchingQuestion.formattedGlobalIndex) {
      blockIndex = matchingQuestion.formattedGlobalIndex;
    } else if (matchingQuestion && matchingQuestion.globalIndex) {
      blockIndex = matchingQuestion.globalIndex;
    }

    return blockIndex;
  };

  if (filter && filter.resultsPath && resultBlocks) {
    const block = resultBlocks[filter.filterName];
    if (
      block &&
      block.question &&
      block.answers &&
      block.answers[filter.value] &&
      block.answers[filter.value].answer
    ) {
      return (
        <>
          Q{`${getBlockIndex(block.id)} `}&nbsp;
          <span className="single-active-filter-label-parentheses">
            (answer: {block.answers[filter.value].answer}
            {filter.additionalAnswerLabel
              ? ` ${filter.additionalAnswerLabel}`
              : ''}
            )
          </span>
        </>
      );
    }

    if (block && block.question && block.type === 'Slider') {
      if (filter.value)
        return (
          <>
            Q{`${getBlockIndex(block.id)} `}&nbsp;
            <span className="single-active-filter-label-parentheses">
              (answer: {filter.value})
            </span>
          </>
        );
      if (block.allowSkip)
        return (
          <>
            Q{`${getBlockIndex(block.id)} `}&nbsp;
            <span className="single-active-filter-label-parentheses">
              (answer: N/A)
            </span>
          </>
        );
    }
  } else if (
    filter &&
    filter.filterName &&
    resultBlocks &&
    resultBlocks[filter.filterName]
  ) {
    const block = resultBlocks[filter.filterName];
    if (block && block.type === 'Matrix' && block.answers) {
      return (
        <>
          Q{`${getBlockIndex(block.id)} `}&nbsp;
          <div
            className="single-active-filter-label-parentheses single-active-filter-overflow"
            title={filter.additionalAnswerLabel}
          >
            (answer:
            {filter.additionalAnswerLabel
              ? ` ${filter.additionalAnswerLabel}`
              : filter.value}
            )
          </div>
        </>
      );
    }
  }

  if (
    filter &&
    filter.filterName &&
    filter.filterName.substring(0, 4) === 'CAT-'
  ) {
    const blockId = filter.filterName.replace('CAT-', '');
    return (
      <>
        Q{`${getBlockIndex(blockId)} `}&nbsp;
        <span className="single-active-filter-label-parentheses">
          (category: {filter.value})
        </span>
      </>
    );
  }

  if (
    filter &&
    (filter.value || filter.value === 0) &&
    extremeUsersFilterLabels[filter.value]
  ) {
    return (
      <>
        <span
          title={filter.filterName}
          className="single-active-extreme-user-filter-name"
        >
          {filter.filterName}
        </span>{' '}
        <span className="single-active-filter-label-parentheses">
          ({extremeUsersFilterLabels[filter.value]})
        </span>
      </>
    );
  }

  if (
    filter &&
    filter.filterName &&
    (filter.filterName === 'genders' ||
      filter.filterName === 'employementTypes' ||
      filter.filterName === 'ageGroups' ||
      filter.filterName === 'languages')
  ) {
    return (
      <>
        {getFilterName(filter.filterName)}&nbsp;
        <span className="single-active-filter-label-parentheses">
          ({filter.value})
        </span>
      </>
    );
  }

  return filter.value;
};

export default FilterLabel;
