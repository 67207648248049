import React, { useState, useRef, useMemo, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';

import getChartData from './helpers/getChartData';

import {
  REMOVE_MERGE_GRAPH,
  UPDATE_MERGE_GRAPH,
  UPDATE_MERGE_GRAPH_QUESTION
} from '../../../../../../../graphql/Survey';

import Icon from '../../../../../Icon';
import Bars from './components/Bars/Bars';
import QuestionsList from './components/QuestionsList/QuestionsList';
import Loader from '../Loader/Loader';
import MergedGraphToolbar from '../Toolbars/MergedGraphToolbar';

import {
  getStatisticalRelevanceForQuestions,
  parseStatisticalAnalysisData
} from './helpers/statisticalRelevance';

import styles from './MergedGraph.module.css';
import colorsPallete from '../../../../colors';

export default ({
  mergedGraph,
  questions,
  surveyId,
  mergedGraphs,
  activeFilters,
  isFiltered,
  parseQuestion,
  onToggleFilter,
  isAllowedToExecuteRequests,
  viewToken,
  setShowAddInsightsPopup
}) => {
  const [graph, setGraph] = useState(mergedGraph);
  const [updateMergeGraph] = useMutation(UPDATE_MERGE_GRAPH);
  const [updateMergeGraphQuestionMutation] = useMutation(
    UPDATE_MERGE_GRAPH_QUESTION
  );
  const [removeMergeGraph, { loading: removeMergeGraphLoading }] = useMutation(
    REMOVE_MERGE_GRAPH
  );
  const barsParent = useRef();
  const [barsVertical, setBarsVertical] = useState(true);
  const [isPercentage, setIsPercentage] = useState(true);
  const [
    fetchedStatisticalRelevance,
    setFetchedStatisticalRelevance
  ] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hiddenQuestionIndexes, setHiddenQuestionIndexes] = useState(
    (graph && graph.hiddenQuestionIndexes) || []
  );

  const mergedGraphQuestions = graph.questions.map(q =>
    questions.find(question => question.id === q.id)
  );

  const data = useMemo(
    () =>
      getChartData(
        mergedGraphQuestions,
        isPercentage,
        isFiltered,
        hiddenQuestionIndexes
      ),
    [activeFilters, isPercentage, mergedGraphs, graph, hiddenQuestionIndexes]
  );

  useEffect(
    () => {
      setGraph(mergedGraph);
    },
    [mergedGraph]
  );

  useEffect(
    () => {
      if (fetchedStatisticalRelevance) {
        if (activeFilters && activeFilters.length) {
          getStatisticalRelevance();
        }
        if (!activeFilters) {
          setFetchedStatisticalRelevance({
            unfilteredResponses:
              fetchedStatisticalRelevance.unfilteredResponses,
            filteredResponses: null
          });
        }
      }
    },
    [activeFilters]
  );

  useEffect(
    () => {
      setHiddenQuestionIndexes((graph && graph.hiddenQuestionIndexes) || []);
    },
    [graph.hiddenQuestionIndexes]
  );

  const statisticalRelevance = useMemo(
    () => {
      if (!fetchedStatisticalRelevance) return null;
      return parseStatisticalAnalysisData(
        fetchedStatisticalRelevance.unfilteredResponses,
        fetchedStatisticalRelevance.filteredResponses,
        mergedGraphQuestions,
        hiddenQuestionIndexes
      );
    },
    [fetchedStatisticalRelevance, hiddenQuestionIndexes]
  );

  const getStatisticalRelevance = async () => {
    if (isAllowedToExecuteRequests) {
      setLoading(true);

      const unparsedStatisticalRelevance = await getStatisticalRelevanceForQuestions(
        mergedGraphQuestions,
        activeFilters,
        surveyId,
        viewToken || null
      );

      setFetchedStatisticalRelevance(unparsedStatisticalRelevance);

      setLoading(false);
    }
    return null;
  };

  const onRemoveMergedChartClick = async () => {
    if (isAllowedToExecuteRequests && !removeMergeGraphLoading) {
      await removeMergeGraph({
        variables: {
          survey: surveyId,
          id: graph.id,
          ...(viewToken ? { viewToken } : {})
        }
      });

      /*
      if (
        result.data &&
        result.data.removeMergedGraph &&
        result.data.removeMergedGraph.survey
      ) {
        const newMergedGraphs = mergedGraphs.filter(
          mergedGraph => mergedGraph.id !== graph.id
        );
        setMergedGraphs(newMergedGraphs);
      }
      */
    }
  };

  const onBarClick = (questionIndex, answerStringIndex) => {
    const clickedBarQuestion = mergedGraphQuestions[questionIndex];
    const answerIndex = parseInt(answerStringIndex, 10);

    if (clickedBarQuestion) {
      if (
        clickedBarQuestion.type === 'Checkboxes' ||
        (clickedBarQuestion.type === 'Multiple Choice Question' &&
          clickedBarQuestion.selectAtMost &&
          clickedBarQuestion.selectAtMost > 1)
      ) {
        onToggleFilter(
          clickedBarQuestion.id,
          null,
          answerIndex,
          null,
          result => {
            if (result && result.attributes.block_results) {
              const questionBlock = result.attributes.block_results.filter(
                question => question.block === clickedBarQuestion.id
              );
              if (questionBlock.length) {
                if (
                  questionBlock[0].answers &&
                  questionBlock[0].answers.indexOf(answerIndex) > -1
                ) {
                  return true;
                }
              }
            }
            return false;
          }
        );
      }
      if (
        clickedBarQuestion.type === 'Multiple Choice Question' &&
        (!clickedBarQuestion.selectAtMost ||
          clickedBarQuestion.selectAtMost === 1)
      ) {
        onToggleFilter(
          clickedBarQuestion.id,
          'answer',
          answerIndex,
          'attributes.block_results'
        );
      }
    }
  };

  const onStatisticalRelevanceClick = async () => {
    if (fetchedStatisticalRelevance) {
      setFetchedStatisticalRelevance(null);
      return null;
    }

    setIsPercentage(true);
    getStatisticalRelevance();
    return null;
  };

  const updateMergedGraph = (
    graphId,
    updateGraphMutation,
    propertiesToUpdate
  ) => {
    if (graphId && surveyId && updateGraphMutation) {
      if (propertiesToUpdate && propertiesToUpdate.hiddenQuestionIndexes) {
        setHiddenQuestionIndexes(propertiesToUpdate.hiddenQuestionIndexes);
      }

      updateGraphMutation({
        variables: {
          id: graphId,
          survey: surveyId,
          ...(propertiesToUpdate || {}),
          ...(viewToken ? { viewToken } : {})
        }
      });
    }
  };

  const updateMergedGraphQuestion = (questionId, propertiesToUpdate) => {
    if (graph && graph.id && surveyId && questionId) {
      updateMergeGraphQuestionMutation({
        variables: {
          graphId: graph.id,
          survey: surveyId,
          question: questionId,
          ...(propertiesToUpdate || {}),
          ...(viewToken ? { viewToken } : {})
        }
      });

      const questionIndexToUpdate =
        graph && graph.questions
          ? graph.questions.findIndex(q => q.id === questionId)
          : null;

      if (questionIndexToUpdate || questionIndexToUpdate === 0) {
        const newGraph = { ...graph };

        if (newGraph.questions && newGraph.questions[questionIndexToUpdate]) {
          newGraph.questions[questionIndexToUpdate] = {
            ...newGraph.questions[questionIndexToUpdate],
            ...(propertiesToUpdate || {})
          };

          setGraph(newGraph);
        }
      }
    }
  };

  const getColorsPalleteForBarGraph = () => {
    const newColorsPallete = [...colorsPallete];

    if (graph && graph.questions && graph.questions.length) {
      graph.questions.forEach((question, index) => {
        if (question && question.colorCode && question.colorCode !== '') {
          newColorsPallete[index] = question.colorCode;
        }
      });
    }

    return newColorsPallete;
  };

  return (
    <div className={styles.container}>
      <MergedGraphToolbar
        onStatisticalRelevanceClick={onStatisticalRelevanceClick}
        fetchedStatisticalRelevance={fetchedStatisticalRelevance}
        barsVertical={barsVertical}
        setBarsVertical={setBarsVertical}
        isPercentage={isPercentage}
        setIsPercentage={setIsPercentage}
        onRemoveMergedChartClick={onRemoveMergedChartClick}
        setShowAddInsightsPopup={setShowAddInsightsPopup}
        questionId={graph ? graph.id : null}
        viewToken={viewToken}
      />
      <div className={styles.blockTitle}>
        <div className={styles.blockIcon}>
          <Icon type="merged-graph" />
        </div>
        <div className={styles.blockContent}>
          <div className={styles.blockText}>{graph.name}</div>
        </div>
        <div className={styles.removeMergedGraph}>
          {removeMergeGraphLoading && <Loader size="small" />}
        </div>
      </div>
      <div className={styles.blockContent}>
        <div className={styles.chartContainer} ref={barsParent}>
          {loading ? (
            <div className={styles.loadingAnimation}>
              <div />
              <div />
              <div />
              <div />
            </div>
          ) : (
            <Bars
              parent={barsParent}
              height="400"
              data={data}
              barsVertical={barsVertical}
              colorsPallete={getColorsPalleteForBarGraph().filter(
                (_c, index) => hiddenQuestionIndexes.indexOf(index) === -1
              )}
              isPercentage={isPercentage}
              isFiltered={isFiltered}
              onBarClick={onBarClick}
              statisticalRelevance={statisticalRelevance}
            />
          )}
        </div>
        <QuestionsList
          graph={graph}
          mergedGraphQuestions={mergedGraphQuestions}
          colorsPallete={colorsPallete}
          hiddenQuestionIndexes={hiddenQuestionIndexes}
          saveMergedGraph={propertiesToUpdate => {
            if (isAllowedToExecuteRequests) {
              updateMergedGraph(graph.id, updateMergeGraph, propertiesToUpdate);
            }
          }}
          saveMergedGraphQuestion={(questionId, propertiesToUpdate) => {
            if (isAllowedToExecuteRequests) {
              updateMergedGraphQuestion(questionId, propertiesToUpdate);
            }
          }}
          parseQuestion={parseQuestion}
        />
      </div>
    </div>
  );
};
