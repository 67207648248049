import Resizer from 'react-image-file-resizer';

export default (
  file,
  forceCompressFormat = 'PNG',
  maxWidth = 2000,
  maxHeight = 2000,
  outputType = 'blob'
) => {
  let compressFormat = forceCompressFormat;
  if (file && (file.type === 'image/jpeg' || file.type === 'image/jpg')) {
    compressFormat = 'JPEG';
  }

  return new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      compressFormat,
      100,
      0,
      uri => {
        resolve(uri);
      },
      outputType
    );
  });
};
