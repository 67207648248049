import React from 'react';

import Icon from '../../../../Icon';
import FiltersRelationLabel from './components/FiltersRelationLabel/FiltersRelationLabel';
import SingleFilter from './components/SingleFilter/SingleFilter';
import FilterLabel from './components/SingleFilter/components/FilterLabel';

import '../FiltersBar.css';

const ActiveFilters = props => {
  const {
    activeFilters,
    onToggleFilter,
    resultBlocks,
    filtersRelation,
    nestedFilters,
    onSetFiltersRelation,
    mutuallyExclusive,
    sortedQuestions,
    showFiltersOnly,
    removeNestedFilter
  } = props;

  if (activeFilters && activeFilters.length > 0) {
    const amountOfDifferentFilters = activeFilters.reduce((countArray, f) => {
      if (!countArray.includes(f.filterName)) {
        return [...countArray, f.filterName];
      }
      return countArray;
    }, []);

    if (
      nestedFilters &&
      nestedFilters.length &&
      amountOfDifferentFilters.length > 1
    ) {
      const singleFilters = activeFilters.reduce((single, f) => {
        if (!nestedFilters.some(nF => nF.filterName === f.filterName)) {
          return [...single, f];
        }
        return single;
      }, []);

      const groupedFilters = nestedFilters.reduce(
        (group, fR) => [
          ...group,
          {
            ...fR,
            subFilters: activeFilters.reduce((acc, curr) => {
              if (curr.filterName === fR.filterName) {
                return [...acc, curr];
              }
              return acc;
            }, [])
          }
        ],
        []
      );

      const visibleFiltersLength = singleFilters.length + groupedFilters.length;

      return (
        <>
          {groupedFilters.map((nestedFilter, index) => (
            <div
              key={`nested-filter-${
                nestedFilter.filterName
              }-${index.toString()}`}
              className="active-filter-row"
            >
              <div
                className={`single-active-filter nested-active-filter ${
                  mutuallyExclusive ? 'single-active-filter-warning' : ''
                }`}
              >
                {nestedFilter.subFilters.map((filter, filterIndex) => (
                  <div
                    key={`nested-sub-filter-${
                      nestedFilter.filterName
                    }-${filterIndex.toString()}`}
                  >
                    <span className="single-active-filter-label">
                      <FilterLabel
                        filter={filter}
                        resultBlocks={resultBlocks}
                        sortedQuestions={sortedQuestions}
                      />
                    </span>
                    {filterIndex !== nestedFilter.subFilters.length - 1 ? (
                      <span
                        className={`${
                          showFiltersOnly
                            ? 'insight-nested-active-filter-filters-relation'
                            : 'nested-active-filter-filters-relation'
                        }`}
                      >
                        {nestedFilter.relation.toUpperCase()}
                      </span>
                    ) : null}
                  </div>
                ))}

                {!showFiltersOnly ? (
                  <span
                    className="remove-single-active-filter"
                    role="presentation"
                    onClick={() => {
                      removeNestedFilter(nestedFilter.filterName);
                    }}
                  >
                    <Icon type="close" style={{ color: '#5200f1' }} />
                  </span>
                ) : null}
              </div>

              <FiltersRelationLabel
                index={index}
                listKey={`nested-filter-relation-${index}`}
                filtersLength={visibleFiltersLength}
                filtersRelation={filtersRelation}
                onSetFiltersRelation={onSetFiltersRelation}
                showFiltersOnly={showFiltersOnly}
              />
            </div>
          ))}

          {singleFilters.map((filter, index) => (
            <div
              key={`nested-single-active-filter-${
                filter.filterName
              }-${index.toString()}`}
              className="active-filter-row"
            >
              <SingleFilter
                filter={filter}
                onToggleFilter={onToggleFilter}
                resultBlocks={resultBlocks}
                mutuallyExclusive={mutuallyExclusive}
                showFiltersOnly={showFiltersOnly}
                sortedQuestions={sortedQuestions}
              />
              <FiltersRelationLabel
                index={index}
                listKey={`filter-relation-${index}`}
                filtersLength={singleFilters.length}
                filtersRelation={filtersRelation}
                onSetFiltersRelation={onSetFiltersRelation}
                showFiltersOnly={showFiltersOnly}
              />
            </div>
          ))}
        </>
      );
    }

    return activeFilters.map((filter, index) => (
      <div
        key={`single-active-filter-${filter.filterName}-${index.toString()}`}
        className="active-filter-row"
      >
        <SingleFilter
          filter={filter}
          onToggleFilter={onToggleFilter}
          resultBlocks={resultBlocks}
          mutuallyExclusive={mutuallyExclusive}
          showFiltersOnly={showFiltersOnly}
          sortedQuestions={sortedQuestions}
        />
        <FiltersRelationLabel
          index={index}
          listKey={`filter-relation-${index}`}
          filtersLength={activeFilters.length}
          filtersRelation={filtersRelation}
          onSetFiltersRelation={onSetFiltersRelation}
          showFiltersOnly={showFiltersOnly}
        />
      </div>
    ));
  }

  return null;
};

export default ActiveFilters;
