import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';

import styles from './Bar.module.css';

const Bar = props => {
  const {
    data,
    width,
    height,
    unFilteredData,
    isFiltered,
    xLabel,
    yLabel,
    axisEdgeLabels,
    questions,
    themeColors,
    dataPointColors,
    fontSize,
    customAxisLabelStyles,
    dataTooltips,
    setDataTooltips,
    onTooltipAdd,
    onTooltipRemove,
    onDataPointClick
  } = props;
  const [svg, setSvg] = useState(null);

  useEffect(() => {
    drawChart(svg, props, dataTooltips);
  }, []);

  useEffect(
    () => {
      drawChart(svg, props, dataTooltips);
    },
    [
      svg,
      data,
      isFiltered,
      axisEdgeLabels,
      questions,
      themeColors,
      unFilteredData,
      dataPointColors
    ]
  );

  const drawChart = (svgRef, chartProps, tooltips) => {
    if (chartProps.parent && chartProps.parent.current.offsetWidth) {
      const {
        numberOfXAnswers,
        numberOfYAnswers,
        unFilteredDataPointColors
      } = chartProps;

      const themeColor = themeColors.reduce(
        (acc, tC) => ({
          ...acc,
          [tC.name]: tC.colorCode
        }),
        {}
      );

      const margin = {
        top: 20,
        right: 20,
        bottom: 60,
        left: 100
      };

      const chartSvg = d3
        .select(svgRef)
        .attr('width', width)
        .attr('height', height);

      chartSvg.selectAll('*').remove();

      const chartWidth = width - margin.left - margin.right;
      const chartHeight = height - margin.top - margin.bottom;

      const chart = chartSvg
        .append('g')
        .attr('width', chartWidth)
        .attr('height', chartHeight)
        .attr('class', 'results-bar-container')
        .attr('transform', `translate(${margin.left}, ${margin.top})`);

      const x = d3.scaleLinear().range([0, chartWidth]);
      x.domain([0, numberOfXAnswers]);

      const y = d3.scaleLinear().range([0, chartHeight]);
      y.domain([numberOfYAnswers, 0]);

      const x6position = numberOfXAnswers * 0.6;
      const y6position = numberOfYAnswers * 0.6;

      const renderTopLeftPath = () => {
        const greenPath = d3.path();
        greenPath.moveTo(x(0), y(y6position));
        greenPath.lineTo(x(x6position), y(y6position));
        greenPath.lineTo(x(x6position), y(numberOfYAnswers));
        greenPath.lineTo(x(0), y(numberOfYAnswers));
        greenPath.closePath();
        return greenPath;
      };

      const renderBottomLeftPath = () => {
        const greenPath = d3.path();
        greenPath.moveTo(x(0), y(0));
        greenPath.lineTo(x(x6position), y(0));
        greenPath.lineTo(x(x6position), y(y6position));
        greenPath.lineTo(x(0), y(y6position));
        greenPath.closePath();
        return greenPath;
      };

      const renderTopRightPath = () => {
        const greenPath = d3.path();
        greenPath.moveTo(x(x6position), y(y6position));
        greenPath.lineTo(x(numberOfXAnswers), y(y6position));
        greenPath.lineTo(x(numberOfXAnswers), y(numberOfYAnswers));
        greenPath.lineTo(x(x6position), y(numberOfYAnswers));
        greenPath.closePath();
        return greenPath;
      };

      const renderBottomRightPath = () => {
        const path = d3.path();
        path.moveTo(x(x6position), y(y6position));
        path.lineTo(x(numberOfXAnswers), y(y6position));
        path.lineTo(x(numberOfXAnswers), y(0));
        path.lineTo(x(x6position), y(0));
        path.closePath();
        return path;
      };

      // Top left path shape
      chart
        .append('path')
        .attr('d', renderTopLeftPath())
        .attr('fill', themeColor.COLOR_1)
        .attr('opacity', 1);

      // Bottom left path shape
      chart
        .append('path')
        .attr('d', renderBottomLeftPath())
        .attr('fill', themeColor.COLOR_3)
        .attr('opacity', 1);

      // Top right path shape
      chart
        .append('path')
        .attr('d', renderTopRightPath())
        .attr('fill', themeColor.COLOR_2)
        .attr('opacity', 1);

      // Bottom right path shape
      chart
        .append('path')
        .attr('d', renderBottomRightPath())
        .attr('fill', themeColor.COLOR_4)
        .attr('opacity', 1);

      // X Axis
      chart
        .append('g')
        .attr('class', styles.customGraphAxis)
        .attr('transform', `translate(0,${chartHeight})`)
        .call(
          d3
            .axisBottom(x)
            .scale(x)
            .ticks(11)
            .tickValues([])
            .tickFormat('')
        )
        .style('color', '#c7c7c7')
        .style('stroke-width', 1)
        .style('font-size', fontSize || '10px');

      // Y Axis
      chart
        .append('g')
        .attr('class', styles.customGraphAxis)
        .call(
          d3
            .axisLeft(y)
            .scale(y)
            .ticks(11)
            .tickValues([])
            .tickFormat('')
        )
        .style('color', '#c7c7c7')
        .style('stroke-width', 1)
        .style('font-size', fontSize || '10px');

      // Background grid horizontal
      chart
        .append('g')
        .attr('class', 'grid')
        .call(
          d3
            .axisLeft(y)
            .ticks(11)
            .tickSize(-width + margin.left + margin.right)
            .tickFormat('')
        )
        .style('color', '#cccccc');

      // Background grid vertical
      chart
        .append('g')
        .attr('class', 'grid')
        .call(
          d3
            .axisBottom(x)
            .ticks(11)
            .tickSize(height - margin.bottom - margin.top)
            .tickFormat('')
        )
        .style('color', '#cccccc');

      // Features calculated weight (x: importance, y: satisfaction) represented by a letter (A, B, C, ...)
      chart
        .selectAll('.feature')
        .data(data)
        .enter()
        .append('circle')
        .attr('cx', d => (d.x || d.x === 0 ? x(d.x) : -150)) // hacky solution to hide data point
        .attr('cy', d => (d.y || d.y === 0 ? y(d.y) : -150)) // hacky solution to hide data point
        .attr('r', 5)
        .attr('fill', (_d, i) => dataPointColors[i])
        .style('opacity', '1')
        .on('mouseover', (d, i) => {
          if (!tooltips.some(t => t.index === i)) {
            onTooltipAdd(setDataTooltips, d, i, 1, dataPointColors);
          }
        })
        .on('mouseout', (_d, i) => {
          if (!tooltips.some(t => t.index === i && t.click)) {
            onTooltipRemove(setDataTooltips, i);
          }
        })
        .on('click', (_d, i) => onDataPointClick(setDataTooltips, i));

      if (isFiltered) {
        // Filtered features
        chart
          .selectAll('.feature')
          .data(unFilteredData)
          .enter()
          .append('circle')
          .attr('cx', d => (d.x || d.x === 0 ? x(d.x) : -150)) // hacky solution to hide data point
          .attr('cy', d => (d.y || d.y === 0 ? y(d.y) : -150)) // hacky solution to hide data point
          .attr('r', 5)
          .attr('fill', (_d, i) => unFilteredDataPointColors[i])
          .style('opacity', '0.4')
          .on('mouseover', (d, i) => {
            if (!tooltips.some(t => t.index === `${i}_unfiltered`)) {
              onTooltipAdd(
                setDataTooltips,
                d,
                i,
                0.4,
                unFilteredDataPointColors,
                true
              );
            }
          })
          .on('mouseout', (_d, i) => {
            if (!tooltips.some(t => t.index === `${i}_unfiltered` && t.click)) {
              onTooltipRemove(setDataTooltips, `${i}_unfiltered`);
            }
          })
          .on('click', (_d, i) =>
            onDataPointClick(setDataTooltips, `${i}_unfiltered`)
          );
      }

      // Lovers benchmark - horizontal
      chart
        .append('line')
        .style('stroke', '#919191')
        .style('stroke-dasharray', '3, 3')
        .style('stroke-width', 2)
        .attr('class', 'gridline')
        .attr('x1', 0)
        .attr('y1', y(8))
        .attr('x2', chartWidth)
        .attr('y2', y(8));

      // Lovers benchmark - vertical
      chart
        .append('line')
        .style('stroke', '#919191')
        .style('stroke-dasharray', '3, 3')
        .style('stroke-width', 2)
        .attr('class', 'gridline')
        .attr('x1', x(8))
        .attr('y1', 0)
        .attr('x2', x(8))
        .attr('y2', chartHeight);

      chart
        .selectAll('.feature')
        .data(dataTooltips)
        .enter()
        .append('rect')
        .attr('width', '75px')
        .attr('height', '21px')
        .attr('x', d => x(d.x) - 37.5)
        .attr('y', d => y(d.y) - 30)
        .attr('rx', '2')
        .attr('font-family', 'Open Sans Regular')
        .attr('font-size', '11px')
        .attr('fill', '#FFFFFF')
        .style('background-color', '#ffffff')
        .style('stroke', '#efefef')
        .style('padding', '10px')
        .style('border-radius', '5px')
        .on('mouseout', d => {
          // Remove to prevent hacky behaviour of tooltip persisting when not clicked
          if (!d.click) {
            onTooltipRemove(setDataTooltips, d.index);
          }
        });

      chart
        .selectAll('.feature')
        .data(dataTooltips)
        .enter()
        .append('text')
        .attr('font-family', 'Open Sans Regular')
        .attr('x', d => x(d.x))
        .attr('y', d => y(d.y) - 15)
        .style('text-anchor', 'middle')
        .text(
          d =>
            `(${d.x ? Math.round(d.x * 100) / 100 : d.x}; ${
              d.y ? Math.round(d.y * 100) / 100 : d.y
            })`
        )
        .attr('font-size', '12px')
        .attr('fill', d => d.color)
        .attr('opacity', d => d.opacity)
        .on('mouseout', d => {
          // Remove to prevent hacky behaviour of tooltip persisting when not clicked
          if (!d.click) {
            onTooltipRemove(setDataTooltips, d.index);
          }
        });

      /* eslint-disable */
      const wrap = (text, w) => {
        text.each(function() {
          var text = d3.select(this),
            words = text
              .text()
              .split(/\s+/)
              .reverse(),
            word,
            line = [],
            lineNumber = 0,
            lineHeight = 1.1, // ems
            x = text.attr('x'),
            y = text.attr('y'),
            dy = 0, //parseFloat(text.attr("dy")),
            tspan = text
              .text(null)
              .append('tspan')
              .attr('x', x)
              .attr('y', y)
              .attr('dy', dy + 'em');
          while ((word = words.pop())) {
            line.push(word);
            tspan.text(line.join(' '));
            if (tspan.node().getComputedTextLength() > w) {
              line.pop();
              tspan.text(line.join(' '));
              line = [word];
              tspan = text
                .append('tspan')
                .attr('x', x)
                .attr('y', y)
                .attr('dy', ++lineNumber * lineHeight + dy + 'em')
                .text(word);
            }
          }
        });
      };
      /* eslint-enable */

      // X Axis edge top label
      chart
        .append('text')
        .append('tspan')
        .text(() => {
          const text = axisEdgeLabels.yTop;
          if (text.length > 15) {
            return `${text.substring(0, 15)}...`;
          }
          return text;
        })
        .attr('text-anchor', 'middle')
        .attr('x', -50)
        .attr('y', 0)
        .style('font-size', fontSize || '11px')
        .style('fill', '#000000')
        .style('transform', 'translate(-5px, 6px)');

      // X Axis edge bottom label
      chart
        .append('text')
        .append('tspan')
        .text(() => {
          const text = axisEdgeLabels.yBottom;
          if (text.length > 15) {
            return `${text.substring(0, 15)}...`;
          }
          return text;
        })
        .attr('text-anchor', 'middle')
        .attr('x', -50)
        .attr('y', height - 80)
        .style('font-size', fontSize || '11px')
        .style('fill', '#000000')
        .style('transform', 'translate(-5px, 6px)')
        .call(wrap, 150);

      // Y Axis edge left label
      chart
        .append('text')
        .append('tspan')
        .text(() => {
          const text = axisEdgeLabels.xLeft;
          if (text.length > 50) {
            return `${text.substring(0, 50)}...`;
          }
          return text;
        })
        .attr('text-anchor', 'start')
        .attr('x', 0)
        .attr('y', height - margin.bottom)
        .style('font-size', fontSize || '11px')
        .style('fill', '#000000')
        .style('transform', 'translate(-5px, 6px)')
        .call(wrap, fontSize ? 80 : 150);

      // Y Axis edge right label
      chart
        .append('text')
        .append('tspan')
        .text(() => {
          const text = axisEdgeLabels.xRight;
          if (text.length > 50) {
            return `${text.substring(0, 50)}...`;
          }
          return text;
        })
        .attr('text-anchor', 'end')
        .attr('x', width - margin.left - margin.right)
        .attr('y', height - margin.bottom)
        .style('font-size', fontSize || '11px')
        .style('fill', '#000000')
        .style('transform', 'translate(-5px, 6px)')
        .call(wrap, 150);
    }
  };

  return (
    <div>
      <svg
        className="filter-results-charts-bar"
        ref={elem => {
          if (elem) {
            setSvg(elem);
          }
        }}
      />
      <span
        className={styles.xAxisLabel}
        style={customAxisLabelStyles || {}}
        title={xLabel}
      >
        {xLabel}
      </span>
      <span
        className={styles.yAxisLabel}
        style={customAxisLabelStyles || {}}
        title={yLabel}
      >
        {yLabel}
      </span>
    </div>
  );
};

export default Bar;
