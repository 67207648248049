import React, { useEffect, useState } from 'react';

import Icon from '../../../SurveyBuilder/components/Icon/Icon';
import Loader from '../../../../../../campaigns/components/CampaignResults/Blocks/Graphs/components/Loader/Loader';

import styles from './SurveyDetails.module.css';

export default ({
  survey,
  updateConfirmationStatus,
  allowedToChangeConfirmSurveyDetails,
  adminNeedsToConfirmDetails,
  incidenceRateLoaded,
  totalIR
}) => {
  const [surveyDetailsConfirmed, setSurveyDetailsConfirmed] = useState(
    survey && survey.surveyDetailsConfirmed
  );
  const [
    surveyDetailsConfirmedByAdmin,
    setSurveyDetailsConfirmedByAdmin
  ] = useState(survey && survey.surveyDetailsConfirmedByAdmin);

  useEffect(
    () => {
      if (
        survey &&
        survey.surveyDetailsConfirmedByAdmin &&
        !surveyDetailsConfirmedByAdmin
      ) {
        setSurveyDetailsConfirmedByAdmin(true);
      }
    },
    [survey]
  );

  const returnSurveyDetails = s => [
    {
      name: 'Customer',
      value:
        (s.recordDetails &&
          s.recordDetails.parent &&
          s.recordDetails.parent.name) ||
        (s.recordDetails && s.recordDetails.name) ||
        '-'
    },
    {
      name: 'Initiative',
      value:
        s.recordDetails && s.recordDetails.name && s.recordDetails.parent
          ? s.recordDetails.name
          : '-'
    },
    {
      name: 'Translation',
      value: s.translationOfOtherSurvey ? 'Yes' : 'No'
    },
    {
      name: 'Sample size',
      value: s.maxResponses || 0
    },
    {
      name: 'Survey questions',
      value: s.longestQuestionsPathCount || 0
    },
    incidenceRateLoaded
      ? {
          name: 'Estimated IR',
          value: totalIR ? `${parseFloat(totalIR * 100).toFixed(0)}%` : '0%'
        }
      : {
          name: 'Estimated IR',
          value: (
            <div style={{ right: '-8px', opacity: '0.5' }}>
              <Loader size="small" />
            </div>
          )
        }
  ];

  return (
    <div className={styles.container}>
      {returnSurveyDetails(survey).map(property => (
        <div className={styles.row}>
          <span>{property.name}</span>
          <span className={styles.value}>{property.value}</span>
        </div>
      ))}
      <div
        className={`${styles.confirmContainer} ${
          !allowedToChangeConfirmSurveyDetails || !incidenceRateLoaded
            ? styles.confirmContainerDisabled
            : ''
        }`}
      >
        <div
          className={styles.checkboxContainer}
          onClick={() => {
            if (allowedToChangeConfirmSurveyDetails && incidenceRateLoaded) {
              const newConfirmValue = adminNeedsToConfirmDetails
                ? !surveyDetailsConfirmedByAdmin
                : !surveyDetailsConfirmed;

              if (adminNeedsToConfirmDetails) {
                setSurveyDetailsConfirmedByAdmin(newConfirmValue);
                updateConfirmationStatus(
                  'surveyDetailsConfirmedByAdmin',
                  newConfirmValue
                );
              } else {
                setSurveyDetailsConfirmed(newConfirmValue);
                updateConfirmationStatus(
                  'surveyDetailsConfirmed',
                  newConfirmValue
                );
              }
            }
          }}
          role="presentation"
        >
          <Icon
            type={
              (adminNeedsToConfirmDetails
              ? surveyDetailsConfirmedByAdmin
              : surveyDetailsConfirmed)
                ? 'checkboxChecked'
                : 'checkboxUnChecked'
            }
          />
        </div>
        <span className={styles.confirmLabel}>
          By checking this box I confirm to have checked all of the above
        </span>
      </div>
    </div>
  );
};
