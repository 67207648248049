import gql from 'graphql-tag';

export const QUESTION_PROPERTIES = `
    id
    type
    media
    mediaFilename
    question

    code

    source
    key
    autocomplete
    enableNextAfterSeconds
    appendAdditionalDataToIframe
    confirmResultWithApi

    allowOther
    allowNoneOfTheAbove
    allowSkip

    minCharacters
    maxCharacters

    minValue
    maxValue

    randomOrder
    rankAnswers

    selectAtLeast
    selectAtMost

    complete
    onlyVisibleForAdmins
    distributeEvenly

    nextFlow
    end

    isCommunityAssignQuestion

    choices {
      id
      answer
      image
      imageFilename
      lockedPosition
      nextFlow
      end
      quotaAmount
      quotaMinAmount
      quotaType
      profilingTag {
        category
        tagName
        tagValue
        excludeDescription
      }
      assignUserToCommunity
      decisionFrameworkType
    }

    branches {
      id
      label
      nextFlow
      quotaAmount
      quotaType
      end
      range {
        min
        max
        nextFlow
      }
    }

    profiling {
      isProfilingQuestion
      isReusedProfilingQuestion
      visibleForSearch
      language
      id
      answerCollected
      reuseQuestionSearchSelection {
        category
        tagName
        tagValue
      }
    }

    matrix {
      decisionFrameworkType
      xLabel
      yLabel
      xQuestion
      yQuestion
      xQuestionId
      yQuestionId
    }

    incidenceRate {
      profilingQuestionCorrectionApplied
      dropoutValueEstimation
    }
`;

const SURVEY_PROPS = `
  id
  client {
    id
    email
    company
    admin
  }
  name
  projectName
  description
  content {
    flows
    groups {
      id
      label
      flows
      randomized
      nextFlow
      end
    }
  }
  questions {
    ${QUESTION_PROPERTIES}
  }
  notes
  image
  imageFilename
  maxResponses
  baseReward
  averageDuration
  status
  paid
  published
  approvalRequestSent
  createdAt
  updatedAt
  resultsCount
  latestResponse
  onlyWebVersion
  webVersionEmailConfirmation
  contactForPrizes
  contactForNextSurvey
  addToMailingListOfCustomer
  askForSocioDemoWebSurvey
  askToRegisterOnBufflWebSurvey
  disableViewLink
  surveyEndSuccessRedirection
  surveyEndFailedRedirection
  surveyQuotaFullRedirection
  hideDownloadBufflInWebSurvey
  hideLogoInWebSurvey
  hideIllustrationsInWebSurvey
  enableCustomCopyInWebSurvey
  hidePostalCodeInWebSurvey
  askForEmailAddressInWebSurvey
  adaptConsentForExternalInWebSurvey
  userFilter
  filterSettings {
    representativity {
      age
      gender
    }
    crossedSample
  }
  schedule {
    id
    action
    time
    status
    creator {
      email
      admin
    }
    createdAt
    updatedAt
  }
  history {
    id
    type
    client {
      email
      admin
    }
    createdAt
    updatedAt
  }
  actionsUsingQuestionDesigner
  mergedGraphs {
    id
    name
    client {
      id
    }
    questions {
      id
      name
      colorCode
    }
    hiddenQuestionIndexes
  }
  hiddenGraphs {
    flows
    mergedGraphs {
      graph
      questions
    }
  }
  language
  customCopies {
    active
    name
    translations {
      KEY
      EN
      NL
      FR
      DE
    }
  }
  customBranding
  customThemeColors {
    name
    colorCode
  }
  customThemeImages {
    name
    imageFilename
  }
  communitySettings {
    profilingTags {
      profilingTag {
        category
        tagName
        tagValue
      }
      isCommunityTag
    }
    allSurveysVisible
    communityType
    seeGiveawayMessage
    thankYouScreenConversionMessage
  }
  communities {
    id
    name
  }
  opportunityGraphs {
    id
    features {
      id
      importanceQuestion,
      satisfactionQuestion,
      description,
      choiceId
    }
    xLabel
    yLabel
    question
  }
  customGraphs {
    id
    features {
      id
      xQuestion,
      yQuestion,
      description,
      choiceId
    }
    xLabel
    yLabel
    question
  }
  conceptTestGraphs {
    id
    features {
      id
      xQuestion,
      yQuestion,
      description,
      choiceId
    }
    xLabel
    yLabel
    question
  }
  valueSelectionGraphs {
    id
    features {
      id
      xQuestion,
      yQuestion,
      description,
      choiceId
    }
    xLabel
    yLabel
    question
  }
  ideaSelectionGraphs {
    id
    features {
      id
      xQuestion,
      yQuestion,
      description,
      choiceId
    }
    xLabel
    yLabel
    question
  }
  generalCommunity
  translationOfOtherSurvey
  relatedSurveys
  recordDetails {
    id
    name
    haveLicence
    permissions
    parent {
      id
      name
      haveLicence
      permissions
    }
  }
  surveyDetailsConfirmed
  surveyDetailsConfirmedByAdmin
  totalIncidenceRate
  participationConfiguration {
    participationCriteria {
      surveyIds
    }
    participationAction
  }
  recordCustomName
  scoping {
    assumptionToValidate
    targetAudienceDescription
    nextSteps {
      _id
      description
    }
  }
  longestQuestionsPathCount
  isOnboardingSurvey
  dontCountInFairUsePolicy
`;

const SURVEY_RESULTS_PROPS = `
  id
  client {
    id
    email
    company
    admin
  }
  content {
    flows
    groups {
      id
      label
      flows
      randomized
      nextFlow
      end
    }
  }
  questions {
    ${QUESTION_PROPERTIES}
  }
  name
  projectName
  description
  notes
  image
  imageFilename
  maxResponses
  status
  paid
  published
  approvalRequestSent
  createdAt
  updatedAt
  resultsCount
  latestResponse
  onlyWebVersion
  webVersionEmailConfirmation
  contactForPrizes
  contactForNextSurvey
  addToMailingListOfCustomer
  askForSocioDemoWebSurvey
  askToRegisterOnBufflWebSurvey
  surveyEndSuccessRedirection
  surveyEndFailedRedirection
  surveyQuotaFullRedirection
  userFilter
  schedule {
    id
    action
    time
    status
    creator {
      email
      admin
    }
    createdAt
    updatedAt
  }
  actionsUsingQuestionDesigner
  mergedGraphs {
    id
    name
    client {
      id
    }
    questions {
      id
      name
      colorCode
    }
    hiddenQuestionIndexes
  }
  wordClouds {
    id
    name
    question
    mergedWords {
      text,
      parentWords
    }
  }
  hiddenGraphs {
    flows
    mergedGraphs {
      graph
      questions
    }
  }
  opportunityGraphs {
    id
    name
    client {
      id
    }
    features {
      id
      importanceQuestion,
      satisfactionQuestion,
      description,
      active
      colorCode
      choiceId
    }
    unFilteredFeatures {
      id
      active
    }
    xLabel
    yLabel
    customThemeColors {
      name
      colorCode
    }
    question
  }
  customGraphs {
    id
    name
    client {
      id
    }
    features {
      id
      xQuestion,
      yQuestion,
      description,
      active
      colorCode
      choiceId
    }
    unFilteredFeatures {
      id
      active
    }
    xLabel
    yLabel
    customThemeColors {
      name
      colorCode
    }
    question
  }
  conceptTestGraphs {
    id
    name
    client {
      id
    }
    features {
      id
      xQuestion,
      yQuestion,
      description,
      active
      colorCode
      choiceId
    }
    unFilteredFeatures {
      id
      active
    }
    xLabel
    yLabel
    customThemeColors {
      name
      colorCode
    }
    question
  }
  valueSelectionGraphs {
    id
    name
    client {
      id
    }
    features {
      id
      xQuestion,
      yQuestion,
      description,
      active
      colorCode
      choiceId
    }
    unFilteredFeatures {
      id
      active
    }
    xLabel
    yLabel
    customThemeColors {
      name
      colorCode
    }
    question
  }
  ideaSelectionGraphs {
    id
    name
    client {
      id
    }
    features {
      id
      xQuestion,
      yQuestion,
      description,
      active
      colorCode
      choiceId
    }
    unFilteredFeatures {
      id
      active
    }
    xLabel
    yLabel
    customThemeColors {
      name
      colorCode
    }
    question
  }
  language
  viewUrl
  insightsData {
    insights {
      _id
      name
      description
      filters {
        activeFilters {
          filterName,
          valuePath
          value
          resultsPath
          transformator
          additionalAnswerLabel
        }
        filterRelation
      }
      questions {
        questionId
        type
      }
      questionSettings {
        activeSorting {
          sortingName
          sortingOrder
        }
        graphFeatures {
          graphId
          featuresVisibility {
            featureId
            active
          }
          unFilteredFeaturesVisibility {
            featureId
            active
          }
          hiddenQuestionIndexes
        }
        activeFilteringOnSelection {
          questionId
          active
        }
        activePresentationMode {
          questionId
          active
        }
      }
    }
  }
  scoping {
    assumptionToValidate
    targetAudienceDescription
    nextSteps {
      _id
      description
    }
  }
`;

export const SURVEY = `
  survey {
    ${SURVEY_PROPS}
  }
  errors {
    type
    message
  }
`;
export const SURVEY_RESULTS = `
  survey {
    ${SURVEY_RESULTS_PROPS}
  }
  errors {
    type
    message
  }
`;
export const SURVEY_QUESTIONGRAPH = `
  survey {
    id
  }
`;

export const GET_SURVEY = gql`
  query Survey($id: ID!) {
    survey(input: { id: $id }) {
      ${SURVEY}
    }
  }
`;

export const GET_SURVEY_RESULTS_PAGE = gql`
  query Survey($id: ID! $viewToken: String) {
    survey(input: { id: $id, viewToken: $viewToken }) {
      ${SURVEY_RESULTS}
    }
  }
`;

export const GET_SURVEY_FRAMEWORKS = gql`
  query Survey($id: ID!, $viewToken: String) {
    survey(input: { id: $id, viewToken: $viewToken }) {
      survey {
        id
        mergedGraphs {
          id
          name
          client {
            id
          }
          questions {
            id
            name
            colorCode
          }
          hiddenQuestionIndexes
        }
        hiddenGraphs {
          flows
          mergedGraphs {
            graph
            questions
          }
        }
        opportunityGraphs {
          id
          features {
            id
            active
          }
          unFilteredFeatures {
            id
            active
          }
        }
        customGraphs {
          id
          features {
            id
            active
          }
          unFilteredFeatures {
            id
            active
          }
        }
        conceptTestGraphs {
          id
          features {
            id
            active
          }
          unFilteredFeatures {
            id
            active
          }
        }
        valueSelectionGraphs {
          id
          features {
            id
            active
          }
          unFilteredFeatures {
            id
            active
          }
        }
        ideaSelectionGraphs {
          id
          features {
            id
            active
          }
          unFilteredFeatures {
            id
            active
          }
        }
      }
    }
  }
`;

export const GET_SURVEY_PUBLISH_PAGE = gql`
  query Survey($id: ID! $includeTotalIncidenceRate: Boolean $includeLongestQuestionsPathCount: Boolean) {
    survey(input: { id: $id, includeTotalIncidenceRate: $includeTotalIncidenceRate, includeLongestQuestionsPathCount: $includeLongestQuestionsPathCount }) {
      ${SURVEY}
    }
  }
`;

export const GET_SURVEY_TOTAL_INCIDENCE_RATE = gql`
  query Survey(
    $id: ID!
    $includeTotalIncidenceRate: Boolean
    $includeLongestQuestionsPathCount: Boolean
  ) {
    survey(
      input: {
        id: $id
        includeTotalIncidenceRate: $includeTotalIncidenceRate
        includeLongestQuestionsPathCount: $includeLongestQuestionsPathCount
      }
    ) {
      survey {
        id
        totalIncidenceRate
      }
    }
  }
`;

export const GET_SURVEY_HISTORY = gql`
  query Survey($id: ID!) {
    survey(input: { id: $id }) {
      survey {
        id
        userFilter
        maxResponses
        allHistory {
          id
          type
          client {
            email
            admin
          }
          createdAt
          updatedAt
          mutation
          arguments
          created
        }
      }
    }
  }
`;

export const DUPLICATE_SURVEY = gql`
  mutation DuplicateSurvey($id: ID!) {
    duplicateSurvey(input: { id: $id }) {
      ${SURVEY}
    }
  }
`;

export const COMBINE_SURVEYS = gql`
  mutation CombineSurveys($surveysToCombine: [SurveyToCombineInput!]!) {
    combineSurveys(input: { surveysToCombine: $surveysToCombine }) {
      survey {
        id
      }
      errors {
        type
        message
      }
    }
  }
`;

export const COMPARE_SURVEYS = gql`
  mutation CompareSurveys(
    $surveysToCompare: [SurveyToCompareInput!]!
    $formattedQuestionIndexes: [String!]!
  ) {
    compareSurveys(
      input: {
        surveysToCompare: $surveysToCompare
        formattedQuestionIndexes: $formattedQuestionIndexes
      }
    ) {
      errors {
        type
        message
      }
    }
  }
`;

export const GET_SURVEYS_QUESTIONGRAPHS = gql`
  query GetSurveysQuestiongraphs($surveysWithQuestiongraphsIDs: [GetSurveysQuestionGraphsInput!]!) {
    getSurveysQuestiongraphs(input: { surveysWithQuestiongraphsIDs: $surveysWithQuestiongraphsIDs }) {
      surveysQuestiongraphs {
        ${SURVEY_QUESTIONGRAPH}
      }
      errors {
        type
        message
      }
    }
  }
`;

export const UPDATE_SURVEY = gql`
  mutation UpdateSurvey(
    $survey: ID!
    $name: String
    $projectName: String
    $description: String
    $image: String
    $imageFilename: String
    $baseReward: PositiveInt
    $averageDuration: PositiveInt
    $notes: String
    $content: SurveyContentInput
    $shortUrlName: String
    $onlyWebVersion: Boolean
    $webVersionEmailConfirmation: Boolean
    $contactForPrizes: Boolean
    $contactForNextSurvey: Boolean
    $askForSocioDemoWebSurvey: Boolean
    $askToRegisterOnBufflWebSurvey: Boolean
    $adaptConsentForExternalInWebSurvey: Boolean
    $addToMailingListOfCustomer: Boolean
    $disableViewLink: Boolean
    $surveyEndSuccessRedirection: URL
    $surveyEndFailedRedirection: URL
    $surveyQuotaFullRedirection: URL
    $approvalRequestSent: Boolean
    $hideDownloadBufflInWebSurvey: Boolean
    $hideLogoInWebSurvey: Boolean
    $hideIllustrationsInWebSurvey: Boolean
    $enableCustomCopyInWebSurvey: Boolean
    $hidePostalCodeInWebSurvey: Boolean
    $askForEmailAddressInWebSurvey: Boolean
    $language: String
    $customBranding: Boolean
    $customThemeColors: [CustomThemeColorsInput!]
    $customThemeImages: [CustomThemeImagesInput!]
    $generalCommunity: Boolean
    $translationOfOtherSurvey: Boolean
    $relatedSurveys: [ID]
    $additionalBudgetCost: AdditionalBudgetCostInput
    $surveyDetailsConfirmed: Boolean
    $surveyDetailsConfirmedByAdmin: Boolean
    $participationConfiguration: ParticipationConfigurationInput
    $record: ID
    $recordCustomName: String
    $scoping: ScopingInput
    $isOnboardingSurvey: Boolean
    $dontCountInFairUsePolicy: Boolean
  ) {
    updateSurvey(
      input: {
        survey: $survey
        name: $name
        projectName: $projectName
        description: $description
        image: $image
        imageFilename: $imageFilename
        baseReward: $baseReward
        averageDuration: $averageDuration
        notes: $notes
        content: $content
        shortUrlName: $shortUrlName
        onlyWebVersion: $onlyWebVersion
        webVersionEmailConfirmation: $webVersionEmailConfirmation
        contactForPrizes: $contactForPrizes
        contactForNextSurvey: $contactForNextSurvey
        askForSocioDemoWebSurvey: $askForSocioDemoWebSurvey
        askToRegisterOnBufflWebSurvey: $askToRegisterOnBufflWebSurvey
        adaptConsentForExternalInWebSurvey: $adaptConsentForExternalInWebSurvey
        addToMailingListOfCustomer: $addToMailingListOfCustomer
        disableViewLink: $disableViewLink
        surveyEndSuccessRedirection: $surveyEndSuccessRedirection
        surveyEndFailedRedirection: $surveyEndFailedRedirection
        surveyQuotaFullRedirection: $surveyQuotaFullRedirection
        approvalRequestSent: $approvalRequestSent
        hideDownloadBufflInWebSurvey: $hideDownloadBufflInWebSurvey
        hideLogoInWebSurvey: $hideLogoInWebSurvey
        hideIllustrationsInWebSurvey: $hideIllustrationsInWebSurvey
        enableCustomCopyInWebSurvey: $enableCustomCopyInWebSurvey
        hidePostalCodeInWebSurvey: $hidePostalCodeInWebSurvey
        askForEmailAddressInWebSurvey: $askForEmailAddressInWebSurvey
        language: $language
        customBranding: $customBranding
        customThemeColors: $customThemeColors
        customThemeImages: $customThemeImages
        generalCommunity: $generalCommunity
        translationOfOtherSurvey: $translationOfOtherSurvey
        relatedSurveys: $relatedSurveys
        additionalBudgetCost: $additionalBudgetCost
        surveyDetailsConfirmed: $surveyDetailsConfirmed
        surveyDetailsConfirmedByAdmin: $surveyDetailsConfirmedByAdmin
        participationConfiguration: $participationConfiguration
        record: $record
        recordCustomName: $recordCustomName
        scoping: $scoping
        isOnboardingSurvey: $isOnboardingSurvey
        dontCountInFairUsePolicy: $dontCountInFairUsePolicy
      }
    ) {
      survey {
        ${SURVEY_PROPS}
      }
      errors {
        type
        message
      }
    }
  }
`;

export const GET_MEDIA_UPLOAD_SAS = gql`
  query GetMediaUploadSAS($fileName: String!) {
    mediaUploadSAS(input: { fileName: $fileName }) {
      token
      uri
      filename
    }
  }
`;
export const UPDATE_TARGET_AUDIENCE = gql`
  mutation UpdateTargetAudience($survey: ID!, $maxResponses: PositiveInt, $userFilter: String, $filterSettings: SurveyFilterSettingsInput) {
    updateTargetAudience(input: { survey: $survey, maxResponses: $maxResponses, userFilter: $userFilter, filterSettings: $filterSettings }) {
      survey {
        ${SURVEY_PROPS}
      }
      errors {
        type
        message
      }
    }
  }
`;

export const ADD_QUESTIONS = gql`
  mutation AddQuestions(
    $survey: ID!
    $questions: [QuestionAddInput]
    $surveyContent: SurveyContentInput!
  ) {
    addQuestions(
      input: {
        survey: $survey
        questions: $questions
        surveyContent: $surveyContent
      }
    ) {
      errors {
        type
        message
      }
    }
  }
`;

export const UPDATE_QUESTION = gql`
  mutation UpdateQuestion($question: QuestionUpdateInputQuestion) {
    updateQuestion(input: { question: $question }) {
      errors {
        type
        message
      }
    }
  }
`;

export const DELETE_QUESTIONS = gql`
  mutation DeleteQuestions(
    $survey: ID!
    $ids: [ID]!
    $surveyContent: SurveyContentInput!
  ) {
    deleteQuestions(
      input: { survey: $survey, ids: $ids, surveyContent: $surveyContent }
    ) {
      errors {
        type
        message
      }
    }
  }
`;

export const REQUEST_APPROVAL = gql`
  mutation RequestApproval($survey: ID!, $status: Boolean) {
    requestApproval(input: { survey: $survey, status: $status }) {
      ${SURVEY}
    }
  }
`;

export const PUBLISH_SURVEY = gql`
  mutation PublishSurvey($survey: ID!, $publish: Boolean) {
    publishSurvey(input: { survey: $survey, publish: $publish }) {
      ${SURVEY}
    }
  }
`;

export const MOVE_NODE = gql`
  mutation MoveNode($survey: ID!, $node: ID!, $group: ID, $fromNode: ID, $fromBranch: ID) {
    moveNode(input: { survey: $survey, node: $node, group: $group, fromNode: $fromNode, fromBranch: $fromBranch}) {
      ${SURVEY}
    }
  }
`;

export const CONVERT_TO_GROUP = gql`
  mutation ConvertToGroup($survey: ID!, $node: ID!) {
    convertToGroup(input: { survey: $survey, node: $node }) {
      ${SURVEY}
      group {
        id
      }
    }
  }
`;

export const MERGE_GROUPS = gql`
  mutation MergeGroups($survey: ID!, $primaryGroup: ID!, $groupToMerge: ID!) {
    mergeGroups(input: { survey: $survey, primaryGroup: $primaryGroup, groupToMerge: $groupToMerge }) {
      ${SURVEY}
    }
  }
`;

export const UNGROUP = gql`
  mutation Ungroup($survey: ID!, $group: ID!) {
    ungroup(input: { survey: $survey, group: $group }) {
      ${SURVEY}
    }
  }
`;

export const UPDATE_GROUP = gql`
  mutation UpdateBasicGroup($survey: ID!, $group: ID!, $label: String!) {
    updateBasicGroup(input: { survey: $survey, group: $group, label: $label }) {
      ${SURVEY}
    }
  }
`;

export const SCHEDULE_SURVEY = gql`
  mutation ScheduleSurvey($survey: ID!, $action: ScheduledTaskAction!, $time: DateTime!) {
    scheduleSurvey( input: { survey: $survey, action: $action, time: $time }) {
      ${SURVEY}
    }
  }
`;

export const RANDOMIZE_GROUP = gql`
  mutation RandomizeGroup(
      $survey: ID!
      $group: ID!
      $toggle: Boolean!
    ) {
    randomizeGroup(
      input: {
        survey: $survey,
        group: $group
        toggle: $toggle,
      }
    ) {
      ${SURVEY}
    }
  }
`;

export const DUPLICATE_NODE = gql`
  mutation DuplicateNode(
      $survey: ID!
      $node: ID!
    ) {
    duplicateNode(
      input: {
        survey: $survey,
        node: $node
      }
    ) {
        ${SURVEY}
        node {
          id
          question {
            id
          }
        }
    }
  }
`;

export const UPDATE_SCHEDULE = gql`
  mutation UpdateSchedule(
    $task: ID!
    $time: DateTime
    $status: ScheduledTaskStatus
  ) {
    updateSchedule(input: { task: $task, time: $time, status: $status }) {
      task {
        id
        action
        time
        status
        createdAt
        deleted
      }
      errors {
        type
        message
      }
    }
  }
`;

export const CREATE_MERGE_GRAPH = gql`
  mutation CreateMergedGraph($survey: ID!, $questions: [ID!], $name: String, $viewToken: String) {
    createMergedGraph(input: { survey: $survey, questions: $questions, name: $name, viewToken: $viewToken }) {
      ${SURVEY}
    }
  }
`;

export const UPDATE_MERGE_GRAPH = gql`
  mutation UpdateMergedGraph($id: ID!, $survey: ID!, $name: String, $viewToken: String, $hiddenQuestionIndexes: [Int!]) {
    updateMergedGraph(input: { id: $id, survey: $survey, name: $name, viewToken: $viewToken, hiddenQuestionIndexes: $hiddenQuestionIndexes }) {
      ${SURVEY}
    }
  }
`;

export const UPDATE_MERGE_GRAPH_QUESTION = gql`
  mutation UpdateMergedGraphQuestion($graphId: ID!, $survey: ID!, $question: ID!, $name: String, $colorCode: String, $viewToken: String) {
    updateMergedGraphQuestion(input: { graphId: $graphId, survey: $survey, question: $question, name: $name, colorCode: $colorCode, viewToken: $viewToken }) {
      ${SURVEY}
    }
  }
`;

export const REMOVE_MERGE_GRAPH = gql`
  mutation RemoveMergedGraph($id: ID!, $survey: ID!, $viewToken: String) {
    removeMergedGraph(input: { id: $id, survey: $survey, viewToken: $viewToken }) {
      ${SURVEY}
    }
  }
`;

export const CREATE_WORD_CLOUD = gql`
  mutation CreateWordCloud(
    $wordClouds: [WordCloudInput]
    $survey: ID!
    $viewToken: String
  ) {
    createWordCloud(
      input: {
        wordClouds: $wordClouds
        survey: $survey
        viewToken: $viewToken
      }
    ) {
      ${SURVEY_RESULTS}
    }
  }
`;

export const UPDATE_WORD_CLOUD = gql`
  mutation UpdateWordCloud(
    $id: ID!
    $name: String
    $survey: ID!
    $mergedWords: [MergedWordInput]
    $viewToken: String
  ) {
    updateWordCloud(
      input: {
        id: $id
        name: $name
        survey: $survey
        mergedWords: $mergedWords
        viewToken: $viewToken
      }
    ) {
      errors {
        type
        message
      }
    }
  }
`;

export const REMOVE_WORD_CLOUD = gql`
  mutation RemoveWordCloud(
    $wordCloud: ID!
    $survey: ID!
    $viewToken: String
  ) {
    removeWordCloud(
      input: {
        wordCloud: $wordCloud
        survey: $survey
        viewToken: $viewToken
      }
    ) {
      ${SURVEY_RESULTS}
    }
  }
`;

export const GET_HIDDEN_WORD_CLOUD_WORDS = gql`
  query GetHiddenWordCloudWords($viewToken: String) {
    getHiddenWordCloudWords(input: { viewToken: $viewToken }) {
      word
    }
  }
`;

export const ADD_HIDDEN_WORD_CLOUD_WORD = gql`
  mutation AddHiddenWordCloudWord($words: [WordInput!]) {
    addHiddenWordCloudWord(input: { words: $words }) {
      done
    }
  }
`;

export const HIDE_RESULT_GRAPHS = gql`
  mutation HideResultGraphs(
    $survey: ID!,
    $flows: [ID]!,
    $mergedGraphs: [MergedGraphInput]!
  ) {
    hideResultGraphs(
      input: {
        survey: $survey,
        flows: $flows,
        mergedGraphs: $mergedGraphs
      }
    ) {
      ${SURVEY}
    }
  }
`;

export const REMOVE_RESULTS = gql`
  mutation RemoveResults(
    $survey: ID!
    $results: [ID]!
    $flagUser: Boolean!
    $questionId: ID
  ) {
    removeResults(
      input: {
        survey: $survey
        results: $results
        flagUser: $flagUser
        questionId: $questionId
      }
    ) {
      errors {
        type
        message
      }
    }
  }
`;

export const CREATE_OPPORTUNITY_GRAPH = gql`
  mutation CreateOpportunityGraph(
    $id: ID
    $survey: ID!
    $name: String!
    $features: [FeatureInput!]
    $xLabel: String
    $yLabel: String
    $question: ID
    $viewToken: String
  ) {
    createOpportunityGraph(
      input: {
        id: $id
        survey: $survey
        name: $name
        features: $features
        xLabel: $xLabel
        yLabel: $yLabel
        question: $question
        viewToken: $viewToken
      }
    ) {
      ${SURVEY_RESULTS}
    }
  }
`;

export const UPDATE_OPPORTUNITY_GRAPH = gql`
  mutation UpdateOpportunityGraph(
    $id: ID!
    $survey: ID!
    $name: String
    $features: [FeatureInput]
    $unFilteredFeatures: [UnfilteredFeatureInput]
    $xLabel: String
    $yLabel: String
    $customThemeColors: [CustomTemplateThemeColorsInput!]
    $viewToken: String
  ) {
    updateOpportunityGraph(
      input: {
        id: $id
        survey: $survey
        name: $name
        features: $features
        unFilteredFeatures: $unFilteredFeatures
        xLabel: $xLabel
        yLabel: $yLabel
        customThemeColors: $customThemeColors
        viewToken: $viewToken
      }
    ) {
      errors {
        type
        message
      }
    }
  }
`;

export const REMOVE_OPPORTUNITY_GRAPH = gql`
  mutation RemoveOpportunityGraph(
    $id: ID!
    $survey: ID!
    $viewToken: String
  ) {
    removeOpportunityGraph(
      input: {
        id: $id
        survey: $survey
        viewToken: $viewToken
      }
    ) {
      ${SURVEY_RESULTS}
    }
  }
`;

export const CREATE_CUSTOM_GRAPH = gql`
  mutation CreateCustomGraph(
    $id: ID
    $survey: ID!
    $name: String!
    $features: [FeatureCustomInput!]
    $xLabel: String
    $yLabel: String
    $question: ID
    $viewToken: String
  ) {
    createCustomGraph(
      input: {
        id: $id
        survey: $survey
        name: $name
        features: $features
        xLabel: $xLabel
        yLabel: $yLabel
        question: $question
        viewToken: $viewToken
      }
    ) {
      ${SURVEY_RESULTS}
    }
  }
`;

export const UPDATE_CUSTOM_GRAPH = gql`
  mutation UpdateCustomGraph(
    $id: ID!
    $survey: ID!
    $name: String
    $features: [FeatureCustomInput]
    $unFilteredFeatures: [UnfilteredFeatureInput]
    $xLabel: String
    $yLabel: String
    $customThemeColors: [CustomTemplateThemeColorsInput!]
    $viewToken: String
  ) {
    updateCustomGraph(
      input: {
        id: $id
        survey: $survey
        name: $name
        features: $features
        unFilteredFeatures: $unFilteredFeatures
        xLabel: $xLabel
        yLabel: $yLabel
        customThemeColors: $customThemeColors
        viewToken: $viewToken
      }
    ) {
      errors {
        type
        message
      }
    }
  }
`;

export const REMOVE_CUSTOM_GRAPH = gql`
  mutation RemoveCustomGraph(
    $id: ID!
    $survey: ID!
    $viewToken: String
  ) {
    removeCustomGraph(
      input: {
        id: $id
        survey: $survey
        viewToken: $viewToken
      }
    ) {
      ${SURVEY_RESULTS}
    }
  }
`;

export const CREATE_CONCEPT_TEST_GRAPH = gql`
  mutation CreateConceptTestGraph(
    $id: ID
    $survey: ID!
    $name: String!
    $features: [FeatureCustomInput!]
    $xLabel: String
    $yLabel: String
    $question: ID
    $viewToken: String
  ) {
    createConceptTestGraph(
      input: {
        id: $id
        survey: $survey
        name: $name
        features: $features
        xLabel: $xLabel
        yLabel: $yLabel
        question: $question
        viewToken: $viewToken
      }
    ) {
      ${SURVEY_RESULTS}
    }
  }
`;

export const UPDATE_CONCEPT_TEST_GRAPH = gql`
  mutation UpdateConceptTestGraph(
    $id: ID!
    $survey: ID!
    $name: String
    $features: [FeatureCustomInput]
    $unFilteredFeatures: [UnfilteredFeatureInput]
    $xLabel: String
    $yLabel: String
    $customThemeColors: [CustomTemplateThemeColorsInput!]
    $viewToken: String
  ) {
    updateConceptTestGraph(
      input: {
        id: $id
        survey: $survey
        name: $name
        features: $features
        unFilteredFeatures: $unFilteredFeatures
        xLabel: $xLabel
        yLabel: $yLabel
        customThemeColors: $customThemeColors
        viewToken: $viewToken
      }
    ) {
      errors {
        type
        message
      }
    }
  }
`;

export const REMOVE_CONCEPT_TEST_GRAPH = gql`
  mutation RemoveConceptTestGraph(
    $id: ID!
    $survey: ID!
    $viewToken: String
  ) {
    removeConceptTestGraph(
      input: {
        id: $id
        survey: $survey
        viewToken: $viewToken
      }
    ) {
      ${SURVEY_RESULTS}
    }
  }
`;

export const CREATE_VALUE_SELECTION_GRAPH = gql`
  mutation CreateValueSelectionGraph(
    $id: ID
    $survey: ID!
    $name: String!
    $features: [FeatureCustomInput!]
    $xLabel: String
    $yLabel: String
    $question: ID
    $viewToken: String
  ) {
    createValueSelectionGraph(
      input: {
        id: $id
        survey: $survey
        name: $name
        features: $features
        xLabel: $xLabel
        yLabel: $yLabel
        question: $question
        viewToken: $viewToken
      }
    ) {
      ${SURVEY_RESULTS}
    }
  }
`;

export const UPDATE_VALUE_SELECTION_GRAPH = gql`
  mutation UpdateValueSelectionGraph(
    $id: ID!
    $survey: ID!
    $name: String
    $features: [FeatureCustomInput]
    $unFilteredFeatures: [UnfilteredFeatureInput]
    $xLabel: String
    $yLabel: String
    $customThemeColors: [CustomTemplateThemeColorsInput!]
    $viewToken: String
  ) {
    updateValueSelectionGraph(
      input: {
        id: $id
        survey: $survey
        name: $name
        features: $features
        unFilteredFeatures: $unFilteredFeatures
        xLabel: $xLabel
        yLabel: $yLabel
        customThemeColors: $customThemeColors
        viewToken: $viewToken
      }
    ) {
      errors {
        type
        message
      }
    }
  }
`;

export const REMOVE_VALUE_SELECTION_GRAPH = gql`
  mutation RemoveValueSelectionGraph(
    $id: ID!
    $survey: ID!
    $viewToken: String
  ) {
    removeValueSelectionGraph(
      input: {
        id: $id
        survey: $survey
        viewToken: $viewToken
      }
    ) {
      ${SURVEY_RESULTS}
    }
  }
`;

export const CREATE_IDEA_SELECTION_GRAPH = gql`
  mutation CreateIdeaSelectionGraph(
    $id: ID
    $survey: ID!
    $name: String!
    $features: [FeatureCustomInput!]
    $xLabel: String
    $yLabel: String
    $question: ID
    $viewToken: String
  ) {
    createIdeaSelectionGraph(
      input: {
        id: $id
        survey: $survey
        name: $name
        features: $features
        xLabel: $xLabel
        yLabel: $yLabel
        question: $question
        viewToken: $viewToken
      }
    ) {
      ${SURVEY_RESULTS}
    }
  }
`;

export const UPDATE_IDEA_SELECTION_GRAPH = gql`
  mutation UpdateIdeaSelectionGraph(
    $id: ID!
    $survey: ID!
    $name: String
    $features: [FeatureCustomInput]
    $unFilteredFeatures: [UnfilteredFeatureInput]
    $xLabel: String
    $yLabel: String
    $customThemeColors: [CustomTemplateThemeColorsInput!]
    $viewToken: String
  ) {
    updateIdeaSelectionGraph(
      input: {
        id: $id
        survey: $survey
        name: $name
        features: $features
        unFilteredFeatures: $unFilteredFeatures
        xLabel: $xLabel
        yLabel: $yLabel
        customThemeColors: $customThemeColors
        viewToken: $viewToken
      }
    ) {
      errors {
        type
        message
      }
    }
  }
`;

export const REMOVE_IDEA_SELECTION_GRAPH = gql`
  mutation RemoveIdeaSelectionGraph(
    $id: ID!
    $survey: ID!
    $viewToken: String
  ) {
    removeIdeaSelectionGraph(
      input: {
        id: $id
        survey: $survey
        viewToken: $viewToken
      }
    ) {
      ${SURVEY_RESULTS}
    }
  }
`;

export const UPDATE_CUSTOM_COPIES = gql`
  mutation UpdateCustomCopies(
    $survey: ID!
    $active: Boolean
    $name: String!
    $translation: CustomCopyTranslationInput
    $language: String!
  ) {
    updateCustomCopies(
      input: {
        survey: $survey
        active: $active
        name: $name
        translation: $translation
        language: $language
      }
    ) {
      errors {
        type
        message
      }
    }
  }
`;

export const CONTACT_VALIDATION_EXPERT = gql`
  mutation ContactInnovationExpert(
    $survey: ID!
    $firstName: String!
    $lastName: String!
    $company: String!
    $email: String!
    $message: String!
    $reCaptcha: String!
    $viewToken: String
  ) {
    contactInnovationExpert(
      input: {
        survey: $survey
        firstName: $firstName
        lastName: $lastName
        company: $company
        email: $email
        message: $message
        reCaptcha: $reCaptcha
        viewToken: $viewToken
      }
    ) {
      success
      errors {
        type
        message
      }
    }
  }
`;

export const UPDATE_SUCCESS_CRITERIA = gql`
  mutation UpdateSuccessCriteria(
    $survey: ID!
    $successCriteria: [SuccessCriteriaInput!]
  ) {
    updateSuccessCriteria(
      input: { survey: $survey, successCriteria: $successCriteria }
    ) {
      errors {
        type
        message
      }
    }
  }
`;

export const GET_SURVEY_CONTENT_QUESTIONS = gql`
  query Survey($id: ID!) {
    survey(input: { id: $id }) {
      survey {
        id
        content {
          flows
          groups {
            id
            label
            flows
            randomized
            nextFlow
            end
          }
        }
        questions {
          ${QUESTION_PROPERTIES}
        }
      }
      errors {
        type
        message
      }
    }
  }
`;

export const UPDATE_SURVEY_COMMUNITY_SETTINGS = gql`
  mutation UpdateSurveyCommunitySettings(
    $survey: ID!
    $profilingTags: [CommunityTagInput]
    $allSurveysVisible: Boolean
    $communityType: CommunityTypes
    $seeGiveawayMessage: Boolean
    $thankYouScreenConversionMessage: ThankYouScreenConversionMessageOptionTypes
  ) {
    updateSurveyCommunitySettings(
      input: {
        survey: $survey
        profilingTags: $profilingTags
        allSurveysVisible: $allSurveysVisible
        communityType: $communityType
        seeGiveawayMessage: $seeGiveawayMessage
        thankYouScreenConversionMessage: $thankYouScreenConversionMessage
      }
    ) {
      errors {
        type
        message
      }
    }
  }
`;

export const GET_SURVEY_COMMUNITIES = gql`
  query GetSurveyCommunities(
    $start: PositiveInt!
    $end: PositiveInt!
    $search: String
    $salt: String
    $type: CommunityManagementType
    $profilingTags: [CommunityTagInput]
    $filterRelation: String
  ) {
    getSurveyCommunities(
      input: {
        start: $start
        end: $end
        search: $search
        salt: $salt
        type: $type
        profilingTags: $profilingTags
        filterRelation: $filterRelation
      }
    ) {
      records {
        id
        name
        profilingTag {
          category
          tagName
          tagValue
        }
        filterRelation
      }
    }
  }
`;

export const UPDATE_SURVEY_COMMUNITIES = gql`
  mutation UpdateSurveyCommunities($survey: ID!, $communities: [ID!]) {
    updateSurveyCommunities(
      input: { survey: $survey, communities: $communities }
    ) {
      errors {
        type
        message
      }
    }
  }
`;

export const GET_SURVEY_FILTERS_DETAILS = gql`
  query GetSurveyFiltersDetails($survey: ID!) {
    getSurveyFiltersDetails(input: { survey: $survey }) {
      filters {
        name
        values
      }
    }
  }
`;
